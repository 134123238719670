import axios, { AxiosError } from "axios";
import CryptoJS from "crypto-js";

import { t } from "i18next";

type PortalApiParams<TVariables, TBody> = {
  path: string;
  body?: TBody;
  query?: string;
  variables?: TVariables;
  token: string | undefined;
};

const url = process.env.REACT_APP_API_URL_LEV;
const secretKey = process.env.REACT_APP_ENCRYPT_KEY;

export const portalAPI = async <
  TData,
  TVariables = Record<string, never>,
  TBody = Record<string, Record<string, string> | unknown>
>({
  path,
  query,
  variables,
  body = {} as TBody,
  token,
}: PortalApiParams<TVariables, TBody>) => {
  if (!token) {
    throw new Error("No API token");
  }

  if (!url) {
    throw new Error("No API URL defined");
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const response = await axios.post<TData>(
    `${url}/${path}`,
    { query, variables, ...body },
    { headers }
  );

  if (response.status >= 300) {
    throw new AxiosError(t("messages.error"));
  }

  return response.data;
};

export const encryptHeader = (value: string) => {
  if (!secretKey) {
    throw new Error("Encryption key is not set in environment variables.");
  }
  const key = CryptoJS.SHA256(secretKey);
  const encrypted = CryptoJS.AES.encrypt(value, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
