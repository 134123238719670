import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  Process,
  useGetContactProcesses,
} from "api/flowable/useGetContactProcesses";
import { Representee, useGetContactInfo } from "api/initial/useGetContactInfo";
import classNames from "classnames";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import i18n from "i18next";
import {
  SelectedContact,
  useGetContractIdData,
} from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { NavigateOptions, To, useNavigate } from "react-router";
import { keycloakService } from "services/keycloakService";
import { useCanDeposit, useCanWithdraw } from "services/permissions/money";
import { initials } from "utils/initials";
import { useModal } from "../Modal/useModal";
import { DepositModalContent } from "../MoneyModals/DepositModalContent/DepositModalContent";
import { WithdrawModalContent } from "../MoneyModals/WithdrawModalContent/WithdrawModalContent";
interface MenuActions {
  logout: () => void;
  deposit: () => void;
  withdraw: () => void;
  process: (to: To, options?: NavigateOptions) => void;
  setSelectedContact: (contact: SelectedContact) => void;
}

const getMenuItems = (
  menuActions: MenuActions,
  hasLinkedContact: boolean,
  hideLogout: boolean,
  canDeposit: boolean,
  canWithdraw: boolean,
  processes: Process[],
  representees: Representee[],
  contactData: SelectedContact,
  selectedContactId: string | number
) => {
  if (!hasLinkedContact) {
    return [
      {
        label: i18n.t("userMenu.logout"),
        action: menuActions.logout,
        Icon: <span className="text-2xl mgc_align_arrow_left_line"></span>,
      },
    ];
  }

  return [
    {
      label: contactData?.userName,
      action: () => {
        menuActions.setSelectedContact(contactData);
      },
      Icon: <span className="text-2xl mgc_user_4_line"></span>,
      selected: contactData?.id === selectedContactId,
    },
    "separator",
    ...(Array.isArray(representees)
      ? representees
          .map((representee, index) => ({
            label: representee?.name,
            action: () => {
              menuActions.setSelectedContact({
                id: representee.id,
                contactId: representee.contactId,
                userName: representee.name,
                initials: initials(representee.name),
              });
            },
            Icon: <span className="text-2xl mgc_user_4_line"></span>,
            selected: representee?.id === selectedContactId,
          }))
          // .slice()
          .sort((a, b) => a.label.localeCompare(b.label))
      : []),
    "separator",
    ...(canDeposit
      ? [
          {
            label: i18n.t("userMenu.deposit"),
            action: menuActions.deposit,
            Icon: (
              <span
                className="text-2xl mgc_align_arrow_right_line
            "
              ></span>
            ),
          },
        ]
      : []),
    "separator",
    ...(canWithdraw
      ? [
          {
            label: i18n.t("userMenu.withdraw"),
            action: menuActions.withdraw,
            Icon: <span className="text-2xl mgc_align_arrow_left_line"></span>,
          },
        ]
      : []),
    "separator",
    ...processes.map((process) => ({
      label: process.name,
      action: () =>
        menuActions.process(`/form/${process.key}`, {
          state: { header: process.name },
        }),
      Icon: <span className="text-2xl mgc_align_arrow_left_line"></span>,
    })),
    "separator",
    ...(!hideLogout
      ? [
          {
            label: i18n.t("userMenu.logout"),
            action: menuActions.logout,
            Icon: <span className="text-2xl mgc_exit_line"></span>,
          },
        ]
      : []),
  ];
};

export const UserMenu = () => {
  const {
    selectedContact,
    selectedContactId,
    setSelectedContactId,
    setSelectedContact,
  } = useGetContractIdData();
  const { t } = useModifiedTranslation();
  const { linkedContact } = useKeycloak();
  const navigate = useNavigate();
  const { data: processes = [] } = useGetContactProcesses();
  const canDeposit = useCanDeposit();
  const canWithdraw = useCanWithdraw();
  const { data: contactData } = useGetContactInfo();
  const {
    Modal,
    onOpen: onDepositModalOpen,
    modalProps: depositModalProps,
    contentProps: depositModalContentProps,
  } = useModal();

  const {
    onOpen: onWithdrawModalOpen,
    modalProps: withdrawModalProps,
    contentProps: withdrawModalContentProps,
  } = useModal();

  const menuActions = {
    logout: () => {
      window.localStorage.removeItem("contact");
      keycloakService.onAuthLogout();
    },
    deposit: () => onDepositModalOpen(),
    withdraw: () => onWithdrawModalOpen(),
    process: (to: To, options?: NavigateOptions) => navigate(to, options),
    setSelectedContact: (contact: SelectedContact) => {
      setSelectedContact(contact);
      setSelectedContactId(contact.id);
      window.localStorage.setItem("contact", JSON.stringify(contact));

      navigate("/", { replace: true });
    },
  };

  const hideLogout = !useMatchesBreakpoint("lg");

  return (
    <>
      <Menu as="div" className="grid relative items-center w-full lg:w-auto">
        <Menu.Button>
          <div className="flex gap-2.5 justify-between lg:justify-start items-center py-2 px-3 bg-ghost-white-60 rounded-lg border border-primary-10">
            <div className="flex gap-2.5 items-center">
              <div className="flex justify-center items-center text-white-100 bg-secondary-100 rounded-md w-[38px] h-[38px]">
                <span className="text-headline-5 font-semibold !leading-4">
                  {selectedContact.initials}
                </span>
              </div>

              <span className="text-body-small text-primary-100">
                {selectedContact.userName}
              </span>
            </div>
            <span className="text-2xl mgc_down_line"></span>
          </div>
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Menu.Items className="overflow-y-auto absolute top-full right-0 z-10 py-1 w-full bg-white-100 rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none min-w-[120px] max-h-[60vh]">
            {getMenuItems(
              menuActions,
              !!linkedContact,
              hideLogout,
              canDeposit,
              canWithdraw,
              processes,
              contactData?.representees || [],
              {
                id: contactData?.contactId || "",
                contactId: contactData?.contactId || "",
                userName: contactData?.name || "-",
                initials: initials(contactData?.name),
              },
              selectedContactId
            ).map((item, index) =>
              typeof item === "string" ? (
                <Separator key={index} />
              ) : (
                <MenuItem key={index} {...item} />
              )
            )}
          </Menu.Items>
        </Transition>
      </Menu>
      <Modal {...depositModalProps} header={t("moneyModal.depositModalHeader")}>
        <DepositModalContent {...depositModalContentProps} />
      </Modal>
      <Modal
        {...withdrawModalProps}
        header={t("moneyModal.withdrawModalHeader")}
      >
        <WithdrawModalContent {...withdrawModalContentProps} />
      </Modal>
    </>
  );
};

interface MenuItemProps {
  label: ReactNode;
  action: () => void;
  Icon: ReactNode;
  selected?: boolean;
}

const Separator = () => {
  return (
    <Menu.Item>
      <hr />
    </Menu.Item>
  );
};

const MenuItem = ({ action, label, Icon, selected = false }: MenuItemProps) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(
            `px-2 py-4 pr-4 flex gap-2 items-center w-full text-body-small font-normal text-primary-100`,
            {
              "bg-secondary-20": active,
            }
          )}
          onClick={action}
        >
          {Icon}
          <div className="items-center pr-2 w-full text-left whitespace-nowrap grow">
            <span>{label}</span>
          </div>
          <span className="">
            {selected && <span className="text-xl mgc_check_line"></span>}
          </span>
        </button>
      )}
    </Menu.Item>
  );
};
