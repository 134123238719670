import { ReactNode } from "react";
import { Transition } from "@headlessui/react";
import { useNavTabProvider } from "providers/NavTabProvider";

interface NavTabMobileWrapperProps {
  children: ReactNode;
}

export const NavTabMobileWrapper = ({ children }: NavTabMobileWrapperProps) => {
  const { isOpen } = useNavTabProvider();

  return (
    <>
      <Transition show={isOpen}>
        <Transition.Child
          className="overflow-y-auto fixed right-full z-30 top-[96px]"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-0"
          enterTo="translate-x-full"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-full"
          leaveTo="translate-x-0"
        >
          {children}
        </Transition.Child>
      </Transition>
    </>
  );
};
