export enum REPORT_KEY {
  START_DATE,
  END_DATE,
  REPORT_DATE,
  PURCHASE_VALUE_TYPE,
  ANALYSIS_MAP,
  SECURITY,
  SECURITY_TYPE,
  GROUP,
  GROUP_BY,
  TOKEN_NAME,
  TOKEN_VALUE,
  VERB_TYPE,
  PORTFOLIO_NAME,
  CONTACT_NAME,
  LOGO_IMAGE,
  LANGUAGE_TYPE,
  TRANSACTION_ID,
}

export enum REPORT_SETTINGS {
  PDF = "pdf",
  EXCEL = "xlsx",
  ASSET = "ASSET",
  ASSETVIS = "ASSETVIS",
  GROUP = "GROUP",
  SECTOR = "SECTOR",
}

export interface ReportSection {
  sectionTitle: string;
  reports: Report[];
}

export interface Report {
  title: string;
  reportId: string;
  isJasper: boolean;
  reportPackage: boolean;
  formats: REPORT_SETTINGS[];
  keys?: REPORT_KEY[];
  groupBy2s: REPORT_SETTINGS[];
  groups: REPORT_SETTINGS[];
}

export interface ReportData {
  report: string;
}
