import { useMutation } from "@tanstack/react-query";
import { portalAPI } from "api/helper";
import { t } from "i18next";
import { useGetSessionToken } from "providers/SessionProvider";

type TradeOrderSignature = {
  reference: string;
  invitations: Invitation[];
};

type Invitation = {
  email: string;
  name: string;
};

async function sendTradeOrderSignature(
  token: string | undefined,
  tradeOrderSignature: TradeOrderSignature
) {
  try {
    const response = await portalAPI({
      path: "document-sign",
      body: tradeOrderSignature,
      token: token,
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(t("messages.failedToContact", { error: error.message }));
    } else {
      throw new Error(t("messages.error"));
    }
  }
}

export const useSendTradeOrderSignature = () => {
  const token = useGetSessionToken();

  const { mutateAsync, isLoading, isError, data } = useMutation(
    ["sendTradeOrderSignature"],
    (tradeOrderSignature: TradeOrderSignature) =>
      sendTradeOrderSignature(token, tradeOrderSignature)
  );

  return {
    sendTradeOrderSignature: mutateAsync,
    isLoading,
    isError,
    data,
  };
};
