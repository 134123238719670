import { ReactNode } from "react";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useScrollDirection, DIRECTION } from "hooks/useScrollPosition";
import { twMerge } from "tailwind-merge";

interface StickyContainerProps {
  children: ReactNode;
  className?: string;
  top?: string;
  stickWithNav?: boolean;
}

export const StickyContainer = ({
  children,
  className,
  top = "top-24",
  stickWithNav,
}: StickyContainerProps) => {
  const isMobile = !useMatchesBreakpoint("lg");
  const { scrollDirection, isAtTop } = useScrollDirection();

  if (isMobile && stickWithNav) {
    const menuIsShowing = isAtTop || scrollDirection === DIRECTION.UP;

    const styles = {
      active: "top-24 transition-all duration-500 ease-in-out",
      hidden: "top-0 transition-all duration-500 ease-in-out",
    };
    return (
      <div
        className={twMerge(
          "sticky",
          className,
          top,
          `${menuIsShowing ? styles.active : styles.hidden}`
        )}
      >
        {children}
      </div>
    );
  }

  return <div className={twMerge("sticky", className, top)}>{children}</div>;
};
