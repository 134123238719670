import { useMutation } from "@tanstack/react-query";
import { portalAPI } from "api/helper";
import { FieldsFile } from "components/ContactModal/EmailIssuersModalContent";
import { t } from "i18next";
import { useGetSessionToken } from "providers/SessionProvider";
import { toast } from "react-toastify";

type IssuerFormData = {
  text: string;
  subject: string;
  externalIds: string[];
  senderName: string;
  senderEmail: string;
  files?: FieldsFile[];
};

type EmailData = {
  text: string;
  subject: string;
  externalIds: string[];
  senderName: string;
  senderEmail: string;
  attachments?: sendGridAttachment[];
};

export type sendGridAttachment = {
  content: string;
  filename: string;
  type: string;
  disposition: string;
};

async function postEmailIssuers(
  emailData: IssuerFormData,
  token: string | undefined
) {
  try {
    const response = await portalAPI({
      path: "send-emails",
      body: emailData,
      token: token,
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(t("messages.failedToContact", { error: error.message }));
    } else {
      throw new Error(t("messages.error"));
    }
  }
}

export const usePostEmailIssuers = () => {
  const token = useGetSessionToken();

  const { mutate, isLoading, isError, data } = useMutation(
    (newEmailData: EmailData) => postEmailIssuers(newEmailData, token),
    {
      onSuccess: () => {
        toast.success(t("contactModal.status.success"), {
          toastId: "contactModalSuccess",
          autoClose: 5000,
        });
      },
      onError: () => {
        toast.error(t("contactModal.status.error"), {
          toastId: "contactModalError",
          autoClose: 5000,
        });
      },
    }
  );

  const sendEmail = async (emailData: IssuerFormData) => {
    const fieldsBase64: sendGridAttachment[] | undefined = [];
    if (emailData.files) {
      for (const file of emailData.files) {
        try {
          fieldsBase64.push({
            content: await getBase64(file.src),
            filename: file.src.name,
            type: file.src.type,
            disposition: "attachment",
          });
        } catch {
          console.error("An error occurred while reading the file.");
        }
      }
    }

    mutate({ ...emailData, attachments: fieldsBase64 });
  };

  return {
    sendEmail,
    isLoading,
    isError,
    data,
  };
};

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject();
  });
}
