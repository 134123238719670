import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { Navigate, useRoutes } from "react-router-dom";
import { NavTabLayout } from "../NavTabLayout";
import { NavTabLayoutMobile } from "../NavTabLayoutMobile";
import { NavTabPath } from "./types";

interface NavTabRoutesProps {
  routes: NavTabPath[];
}
export const NavTabRoutes = ({ routes }: NavTabRoutesProps) => {
  const isMobile = !useMatchesBreakpoint("lg");

  const Layout = isMobile ? NavTabLayoutMobile : NavTabLayout;

  return useRoutes([
    {
      path: "",
      element: <Layout routes={routes} />,
      children: routes,
    },
    {
      path: "*",
      element: (
        <Navigate
          to={routes.find((route) => !route.disabled)?.path ?? "/contact"}
          replace
        />
      ),
    },
  ]);
};
