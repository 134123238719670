import { Buffer } from "buffer";

export const getSustainabilityArticleNumber = (string: string | undefined) => {
  if (!string) return "";
  const parts = string.split(" ");
  const lastPart = parts[parts.length - 1];
  return !isNaN(Number(lastPart)) ? lastPart : "";
};

const DELIMITER = "~";

export const encodeValues = (values: string[]) => {
  const filteredValues = values.filter((value) => !value.includes(DELIMITER));
  const joinedValues = filteredValues.join(DELIMITER);

  let encodedString;

  if (typeof window.btoa === "function") {
    // Use btoa in modern browsers
    encodedString = window.btoa(joinedValues);
  } else {
    // Use Buffer.from in Node.js
    encodedString = Buffer.from(joinedValues, "utf8").toString("base64");
  }

  return encodedString;
};

export const decodeValues = (encoded: string) => {
  let decodedString;

  if (typeof window.atob === "function") {
    // Use atob in modern browsers
    decodedString = window.atob(encoded);
  } else {
    // Use Buffer.from in Node.js
    decodedString = Buffer.from(encoded, "base64").toString("utf8");
  }

  let values;

  if (typeof TextDecoder === "function") {
    // Use TextDecoder in modern browsers
    const charCodeArray = new Uint8Array(decodedString.length);
    for (let i = 0; i < decodedString.length; i++) {
      charCodeArray[i] = decodedString.charCodeAt(i);
    }

    const decoder = new TextDecoder("iso-8859-1");
    values = decoder.decode(charCodeArray).split(DELIMITER);
  } else {
    // Fallback for handling special characters in Node.js
    values = decodedString.split(DELIMITER);
  }

  return values;
};

export const TAGS_MAP: Readonly<Record<string, string>> = {
  "Finansportalen:Översikt": "overview",
  "Finansportalen:Innehav": "holdings",
  "Finansportalen:Transaktioner": "transactions",
  "Finansportalen:Ordrar": "orders",
  "Finansportalen:Avkastning": "performance",
  "Finansportalen:Rapporter": "reports",
  "Finansportalen:Dokument": "documents",
  "Finansportalen:Fondlista": "securities",
  "Finansportalen:Bokföring": "bookkeeping",
  "Finansportalen:Limiter": "limits",
  "Finansportalen:Fondbolag": "issuers",
  "Finansportalen:Kontakt": "contact",
  "Finansportalen:Fondnyheter": "feed",
  "Finansportalen:Hållbarhet": "sustainability",
};

export const formatDate = (date: string) => {
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};
