import { ReactNode } from "react";

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <div
      style={{ contain: "inline-size" }}
      className="flex relative flex-col flex-1 gap-4 lg:gap-8 p-4 xl:p-16 lg:py-16 lg:mt-0 w-screen lg:max-w-[calc(100wv-200px] mt-[96px] lg-px-8"
    >
      {children}
    </div>
  );
};
