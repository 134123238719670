import { useGetAllPortfoliosHoldings } from "api/holdings/useGetAllPortfoliosHoldings";
import { useGetIssuersDetails } from "api/issuer/useGetIssuersDetails";
import { useGetSecurities } from "api/securities/useGetSecurities";

export enum VALID_ASSET_TYPES {
  ALL = "NO_SELECTED_ASSET",
  SWEDEN_STOCKS = "SA",
  GLOBAL_STOCKS = "GA",
  ALTERNATIVE = "3ALTINV",
  NOMINAL = "NR",
  REALA = "RR",
}

export enum HOLDINGS_SETTINGS {
  ALL = "All",
  PORTFOLIO = "Portfolio",
}

export type SecurityInfo = {
  code: string;
  name: string;
};

interface useGetRelevantIssuersProps {
  holdingsSetting: HOLDINGS_SETTINGS;
  assetTypeSetting: VALID_ASSET_TYPES | null;
}

type AssetTypeCodeMap = Map<string, SecurityInfo[]>;

export const useGetRelevantIssuers = ({
  holdingsSetting,
  assetTypeSetting,
}: useGetRelevantIssuersProps) => {
  const { data: portfolioHoldingsData, error: portfolioHoldingsError } =
    useGetAllPortfoliosHoldings();

  const {
    data: allSecuritiesData,
    error: allSecuritiesError,
    loading: allSecuritiesLoading,
  } = useGetSecurities();

  const { data: partnerIssuers } = useGetIssuersDetails();
  const partnersIds = new Set(
    partnerIssuers?.map((issuer) => issuer.externalId)
  );

  const assetTypeCodeMap: AssetTypeCodeMap = new Map(
    Object.values(VALID_ASSET_TYPES).map((category) => [category, []])
  );

  const addIssuerToMap = (issuerInfo: SecurityInfo, category: string) => {
    const issuers = assetTypeCodeMap.get(category); // skapas
    if (issuers && !issuers.some((issuer) => issuer.code === issuerInfo.code)) {
      issuers.push(issuerInfo);
    }
  };

  const processPortfolioHoldings = () => {
    portfolioHoldingsData?.allocationByType
      .flatMap((allocation) => allocation.allocationsBySector)
      .filter((allocationGroup) => assetTypeCodeMap.has(allocationGroup.code))
      .forEach((allocation) => {
        allocation.allocationsBySecurity.forEach((security) => {
          const { class2Code: code, class2Name: name } = security.security;
          if (code && name && partnersIds.has(code)) {
            addIssuerToMap({ code, name }, allocation.code);
          }
        });
      });
  };

  const processAllSecurities = () => {
    allSecuritiesData?.forEach((security) => {
      const assetTypeCode =
        security.sectorCode as keyof typeof VALID_ASSET_TYPES;
      if (security.issuer && assetTypeCodeMap.has(assetTypeCode)) {
        const issuerInfo: SecurityInfo = {
          code: security.issuer.id.toString(),
          name: security.issuer.name,
        };
        if (security.issuer && partnersIds.has(security.issuer.id.toString())) {
          addIssuerToMap(issuerInfo, assetTypeCode);
        }
      }
    });
  };

  if (holdingsSetting === "Portfolio") {
    processPortfolioHoldings();
  } else if (holdingsSetting === "All") {
    processAllSecurities();
  }

  let relevantIssuers: SecurityInfo[] = [];

  if (assetTypeSetting && assetTypeSetting !== VALID_ASSET_TYPES.ALL) {
    relevantIssuers = assetTypeCodeMap.get(assetTypeSetting) || [];
  } else {
    const flattenedArray = Array.from(assetTypeCodeMap.values()).flat();
    relevantIssuers = Array.from(
      new Map(flattenedArray.map((item) => [item["code"], item])).values()
    );
  }

  return {
    data: relevantIssuers,
    loading: allSecuritiesLoading || portfolioHoldingsData,
    error: allSecuritiesError || portfolioHoldingsError,
  };
};
