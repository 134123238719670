import React from "react";
// import styles from "./Hero.module.css";
type HeroProps = {
  imageSrc: string;
  caption:
    | {
        discriminant: false;
      }
    | {
        discriminant: true;
        value: React.ReactNode;
      };
};

export function Hero({ imageSrc, caption }: HeroProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        className="w-full bg-center bg-cover rounded-lg bg-white min-h-[400px]"
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
      {caption.discriminant ? (
        <div style={{ textAlign: "center" }}>{caption.value}</div>
      ) : null}
    </div>
  );
}
