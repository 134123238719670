import { ReactNode } from "react";
interface SuspenseProps {
  isLoading?: boolean;
  isError: boolean;
  loadingComponent?: ReactNode;
  errorComponent: ReactNode;
  children: ReactNode;
  className?: string;
}

export const Suspense = ({
  isLoading = false,
  isError = false,
  loadingComponent,
  errorComponent,
  children,
  className,
}: SuspenseProps) => {
  return (
    <>
      {(isLoading || isError) && (
        <div className={className}>
          {!isLoading && isError && errorComponent}
          {isLoading && !isError && loadingComponent}
        </div>
      )}
      {!isLoading && !isError && children}
    </>
  );
};
