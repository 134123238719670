import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";

interface HasAcceptedTOCQuery {
  loadPreference: string;
}

const HAS_ACCEPTED_QUERY = gql`
  query useGetHasAcceptedTOC {
    loadPreference(prefKey: "termsOfCondition")
  }
`;

export const useGetHasAcceptedTOC = () => {
  const { loading, error, data } = useQuery<HasAcceptedTOCQuery>(
    HAS_ACCEPTED_QUERY,
    {
      ...getFetchPolicyOptions(`useGetHasAcceptedTOC`),
      fetchPolicy: "network-only",
    }
  );

  let hasAccepted;

  if (data && data.loadPreference) {
    hasAccepted =
      JSON.parse(data.loadPreference).acceptedVersion ===
      process.env.REACT_APP_TERMS_USE_VERSION;
  }

  return {
    loading,
    error,
    data: hasAccepted,
  };
};
