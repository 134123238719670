import classNames from "classnames";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { convertToPercent } from "../helpers/convertToPercent";

export const Line = ({
  minValue,
  maxValue,
  showLabels = false,
  height = 20,
  fontSize = "xsmall",
  status,
}: {
  minValue: number;
  maxValue: number;
  showLabels?: boolean;
  height?: number;
  fontSize?: string;
  status?: "ok" | "info" | "warning" | undefined;
}) => {
  const { t } = useModifiedTranslation();

  return (
    <>
      <div
        style={{
          left: `${convertToPercent(minValue)}%`,
          width: `${convertToPercent(maxValue - minValue)}%`,
          height: `${height}px`,
        }}
        className={classNames(
          `w-full absolute rounded-full flex justify-between items-center px-2`,
          {
            "bg-secondary-100": status === "ok",
            "bg-other-orange": status === "info",
            "bg-other-red": status === "warning",
            "bg-secondary-20": !status,
          }
        )}
      >
        {showLabels && (
          <>
            <div
              className={classNames(
                `text-body-${fontSize} md:font-semibold text-white-100`,
                {
                  "!text-primary-100": !status,
                }
              )}
            >
              {t("numberWithSign", {
                value: convertToPercent(minValue),
                sign: "%",
              })}
            </div>
            <p
              className={classNames(
                `text-body-${fontSize} md:font-semibold text-white-100`,
                {
                  "!text-primary-100": !status,
                }
              )}
            >
              {t("numberWithSign", {
                value: convertToPercent(maxValue),
                sign: "%",
              })}
            </p>
          </>
        )}
      </div>
    </>
  );
};
