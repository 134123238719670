import { ReactNode } from "react";
import classNames from "classnames";

type ColorScheme = "gray" | "green" | "red" | "blue";

interface BadgeProps {
  children: ReactNode;
  colorScheme?: ColorScheme;
  truncate?: boolean;
}

export const Badge = ({
  children,
  colorScheme = "gray",
  truncate,
}: BadgeProps) => (
  <div
    className={classNames(
      `py-1 px-2 sm:py-2 sm:px-3 bg-gray-100 rounded text-body-xsmall font-normal w-fit max-w-full h-fit`,
      {
        truncate,
        "bg-primary-20 text-primary-100": colorScheme === "gray",
        "bg-dark-green-20 text-dark-green-100": colorScheme === "green",
        "bg-dark-red-20 text-dark-red-100": colorScheme === "red",
        "bg-dark-blue-20 text-dark-blue-100": colorScheme === "blue",
      }
    )}
  >
    {children}
  </div>
);
