import { Allocation } from "api/securities/types";

export interface AllocationBySecurity {
  code: string;
  name: string;
  security: {
    class2Code: string | null;
    class2Name: string | null;
    id: number;
    isinCode: string;
    countryCode: string;
    currencyCode: string;
    tagsAsList: string[];
  };
  figures: {
    marketValue: number;
    tradeAmountOrig: number;
    fxRate: number;
    amount: number;
    shareOfTotal: number;
    twr: number;
  };
}

export interface AllocationByType {
  code: string;
  name: string;
  figures: {
    marketValue: number;
    tradeAmountOrig: number;
    shareOfTotal: number;
    amount: number;
    twr: number;
  };
  allocationsBySector: AllocationBySector[];
}

export interface ContactHoldingDetailsQuery {
  contact: {
    id: number;
    portfolios: {
      id: number;
      portfolioReport: {
        holdingPositions: HoldingPosition[];
      };
    }[];
  };
}

export interface AllocationBySector {
  code: string;
  name: string;
  figures: {
    marketValue: number;
    tradeAmountOrig: number;
    shareOfTotal: number;
    amount: number;
    twr: number;
  };
  allocationsBySecurity: AllocationBySecurity[];
}

export interface AllAllocations {
  marketValue: number;
  tradeAmountOrig: number;
  shareOfTotal: number;
  amount: number;
  twr: number;
}
export interface AllPortfoliosHoldingsQuery {
  contact: {
    analytics: {
      allocationTopLevel: {
        portfolio: {
          currencyCode: string;
        };
        allAllocations: AllAllocations;
        allocationByType: AllocationByType[];
      };
    };
  };
}

export interface PortfolioHoldingsQuery {
  analytics: {
    allocationTopLevel: {
      portfolio: {
        currencyCode: string;
      };
      allAllocations: AllAllocations;
      allocationByType: AllocationByType[];
    };
  };
}

export interface MarketHistoryDataPoint {
  price: number;
  date: string;
}

export enum SecurityTypeCode {
  EQUITY = "E", //Equity
  ETF = "CE", //Exchange-traded funds (ETFs)
  PRIVATE_EQUITY = "PE", //Private Equity
  DEBT_INSTRUMENT = "D", //Debt instruments
  COLLECTIVE_INVESTMENT_VEHICLE = "C", //Collective investment vehicles
  CURRENCY = "TC", //Currencies
}

export enum SecurityTradeType {
  buyUnits = "Trade type:Buy units",
  buyTradeAmount = "Trade type:Buy trade amount",
  sellUnits = "Trade type:Sell units",
  sellTradeAmount = "Trade type:Sell trade amount",
}

export interface Issuer {
  name: string;
  id: number;
}

export interface SecurityDetailsPosition {
  id: number;
  name: string;
  securityCode: string;
  isinCode: string;
  url: string;
  url2: string;
  currency: {
    securityCode: string;
    amountDecimalCount: number;
  };
  latestMarketData?: {
    price: number;
    date: string;
  };
  allocations: Allocation[];
  namesAsMap: Record<string, string>;
  type: {
    code: SecurityTypeCode;
    namesAsMap: Record<string, string>;
    name: string;
  };
  fxRate: number;
  // misnamed on backend, should be tagsAsList
  tagsAsSet: string[];
  fee: number;
  classType5: {
    value: string;
    code: string;
  } | null;
  issuerTmp: {
    name: string;
    id: string;
  } | null;
  issuer: Issuer;
  documents: {
    fileName: string;
    identifier: string;
    mimeType: string;
  }[];
  amountDecimalCount: number;
}

export interface SecurityDetailsQuery {
  security: SecurityDetailsPosition;
}

export interface SecurityMarketDataHistory {
  marketDataHistory: MarketHistoryDataPoint[];
}

export interface SecurityMarketDataHistoryQuery {
  security: SecurityMarketDataHistory;
}

export interface HoldingPosition {
  security: {
    id: number;
  };
  amount: number;
  tradeAmountOrig: number;
  accruedInterest: number;
  marketValue: number;
  valueChangeAbsolute: number;
  valueChangeRelative: number;
  marketFxRate: number;
  twr: number;
  shareOfPortfolio: number;
}

export interface AllPortfoliosHoldingDetailsQuery {
  contact: {
    portfolioReport: {
      holdingPositions: HoldingPosition[];
    };
  };
}

export interface PortfolioHoldingDetailsQuery {
  portfolio: {
    portfolioReport: {
      holdingPositions: HoldingPosition[];
    };
  };
}

export interface SecurityPosition {
  accruedInterest: number;
  amount: number;
  marketFxRate: number;
  marketValue: number;
  tradeAmountOrig: number;
  shareOfTotal: number;
  unrealizedProfits: number;
}

interface Security {
  name: string;
  security: {
    id: number;
  };
  grouppedAnalyticsTimePeriod: SecurityPosition[];
}

export interface CombinedPortfolioHoldingDetailsQuery {
  analytics: {
    grouppedAnalytics: {
      grouppedAnalytics: Security[];
    };
  };
}
export interface AnalyticsSecurityTypeData {
  code: string;
  name: string;
  firstAnalysis: {
    marketValue: number;
    tradeAmount: number;
    shareOfTotal: number;
  } | null;
}

export interface AnalyticsSecurityData {
  security: {
    id: number;
    isinCode: string;
    countryCode: string;
    currencyCode: string;
    tagsAsList: string[];
    securityTypeCode: string;
  };
  code: string;
  name: string;
  firstAnalysis: {
    marketValue: number;
    tradeAmount: number;
    amount: number;
    accruedInterest: number;
    purchaseTradeAmount: number;
    tradeAmountOrig: number;
    marketFxRate: number;
    unrealizedProfits: number;
    shareOfTotal: number;
  } | null;
}

export interface AnalyticsSecurityTypeDataWithSecurityData
  extends AnalyticsSecurityTypeData {
  securities: AnalyticsSecurityData[];
}
export interface ContactHoldingsFromAnalyticsQuery {
  contact: {
    id: number;
    analytics: {
      contact: {
        firstAnalysis: {
          marketValue: number;
          tradeAmount: number;
        } | null;
        securityTypes: AnalyticsSecurityTypeDataWithSecurityData[];
      };
    };
  };
}
