import { createContext, ReactNode, useContext, useState } from "react";
import { REPORT_SETTINGS } from "api/reports/types";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export enum ACTION_TYPES {
  SET_START_DATE,
  SET_END_DATE,
  SET_PERIOD,
}

export interface Option {
  id: REPORT_SETTINGS;
  label: string;
}

type ContextProps = {
  reportFormat: REPORT_SETTINGS;
  reportFormatOption: Option;
  setReportFormatOption: (option: Option) => void;
  formatOptions: Option[];
};

export interface FormatOptionsProps {
  [key: string]: Option;
}

const reportFormatKey = "reportFormat";

export const ReportSettingsContext = createContext<ContextProps | undefined>(
  undefined
);

export const ReportSettingsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useModifiedTranslation();

  const formatOptions: FormatOptionsProps = {
    [REPORT_SETTINGS.PDF]: {
      id: REPORT_SETTINGS.PDF,
      label: t("reportsPage.reportFormat.pdf"),
    },
    [REPORT_SETTINGS.EXCEL]: {
      id: REPORT_SETTINGS.EXCEL,
      label: t("reportsPage.reportFormat.excel"),
    },
  };

  const getFormat = () => {
    const savedFormat = window.localStorage.getItem(reportFormatKey);
    const current = savedFormat || REPORT_SETTINGS.PDF;
    return formatOptions[current];
  };
  const [reportFormatOption, _setReportFormatOption] =
    useState<Option>(getFormat);

  const setReportFormatOption = (selectedOption: Option) => {
    if (selectedOption?.id !== null) {
      _setReportFormatOption(selectedOption);
      window.localStorage.setItem(
        reportFormatKey,
        selectedOption.id.toString()
      );
    }
  };

  return (
    <ReportSettingsContext.Provider
      value={{
        reportFormat: reportFormatOption?.id,
        reportFormatOption,
        setReportFormatOption,
        formatOptions: Object.values(formatOptions),
      }}
    >
      {children}
    </ReportSettingsContext.Provider>
  );
};

export const useGetReportSettingsData = () => {
  const state = useContext(ReportSettingsContext);
  if (!state) throw new Error("detail data not found");

  return state;
};
