import CaptorFull from "assets/issuersLogo/CaptorFull.png";
import CaptorIcon from "assets/issuersLogo/CaptorIcon.png";
import CWWFull from "assets/issuersLogo/CWWFull.svg";
import CWWIcon from "assets/issuersLogo/CWWIcon.svg";
import DidnerFull from "assets/issuersLogo/DidnerFull.svg";
import DidnerIcon from "assets/issuersLogo/DidnerIcon.svg";
import EnterFull from "assets/issuersLogo/EnterFull.png";
import EnterIcon from "assets/issuersLogo/EnterIcon.svg";
import EvliFull from "assets/issuersLogo/EvliFull.svg";
import EvliIcon from "assets/issuersLogo/EvliIcon.svg";
import classNames from "classnames";

export const issuerLogos: { [key: number]: { icon: string; full: string } } = {
  184316: { icon: DidnerIcon, full: DidnerFull },
  184310: { icon: EnterIcon, full: EnterFull },
  184307: { icon: EvliIcon, full: EvliFull },
  184317: { icon: CWWIcon, full: CWWFull },
  184308: { icon: CaptorIcon, full: CaptorFull },

  184301: { icon: DidnerIcon, full: DidnerFull },
  184300: { icon: EnterIcon, full: EnterFull },
  184304: { icon: EvliIcon, full: EvliFull },
};

interface IssuerLogoProps {
  name: string;
  id: number;
  type?: "icon" | "full";
  className?: string;
}

const IssuerLogo = ({
  name,
  id,
  type = "icon",
  className,
}: IssuerLogoProps) => {
  const logoData = issuerLogos[id];
  if (!logoData) {
    return null;
  }

  const logo = type === "icon" ? issuerLogos[id].icon : issuerLogos[id].full;

  return (
    <img className={classNames(className)} src={logo} alt={`${name} logo`} />
  );
};

export default IssuerLogo;
