import classNames from "classnames";
interface FilterHeaderButtonProps {
  title?: string;
  classes?: string;
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const FilterHeaderButton = ({
  title,
  classes,
  onClick,
  disabled,
  children,
}: FilterHeaderButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames("group", classes)}
    >
      <span className="text-body-xsmall font-normal ">{title}</span>
    </button>
  );
};
