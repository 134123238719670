import { Squash as Hamburger } from "hamburger-react";
import theme from "tailwindTheme";

interface HamburgerMenuProps {
  toggled: boolean;
  handleToggle: () => void;
}

export const HamburgerMenu = ({
  toggled,
  handleToggle,
}: HamburgerMenuProps) => {
  return (
    <Hamburger
      onToggle={handleToggle}
      toggled={toggled}
      color={theme.colors.secondary[100]}
    />
  );
};
