import { Fragment } from "react";
import { RadioGroup } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

export interface Option {
  id: number | string;
  label: string;
}

interface ButtonRadioProps<T>
  extends Omit<React.ComponentProps<typeof RadioGroup>, "onChange" | "value"> {
  value: T | undefined;
  options: T[];
  disabledOptions?: T[];
  onChange: (option: T) => void;
  variant?: keyof VariantStyles;
}

interface Styles {
  normal: string;
  disabled: string;
  checked: string;
}

interface VariantStyles {
  primary: Styles;
  secondary: Styles;
}

const styles: VariantStyles = {
  primary: {
    normal: "hover:bg-gray-100",
    checked: "bg-secondary-20 text-secondary-80 hover:bg-secondary-20",
    disabled: "bg-gray-500 text-gray-300 pointer-events-none",
  },
  secondary: {
    normal: "hover:bg-gray-200/80 text-black bg-gray-200",
    checked: "bg-white-100 text-black hover:bg-white-100/50",
    disabled: "bg-gray-200 text-green-100",
  },
};

export const ButtonRadio = <TOption extends Option>({
  value,
  options,
  disabledOptions = [],
  onChange,
  className,
  variant = "primary",
}: ButtonRadioProps<TOption>) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={twMerge(
        "flex overflow-hidden items-center w-full font-medium text-gray-500 rounded border border-gray-300 divide-x text-normal",
        className
      )}
    >
      {options.map((option) => (
        <RadioGroup.Option
          as={Fragment}
          value={option}
          key={option.id}
          disabled={
            disabledOptions.findIndex(({ id }) => id === option.id) !== -1
          }
        >
          {({ checked, disabled }) => (
            <div
              className={twMerge(
                "p-1.5 text-center grow cursor-pointer focus:ring-0 transition-colors",
                styles[variant].normal,
                checked && styles[variant].checked,
                disabled && styles[variant].disabled
              )}
            >
              {option.label}
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};
