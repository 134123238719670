import { ReactComponent as LogoutIcon } from "assets/logout.svg";
import i18n from "i18next";
import { keycloakService } from "services/keycloakService";

export const LogoutButton = () => {
  const logout = () => {
    window.localStorage.removeItem("contact");
    keycloakService.onAuthLogout();
  };

  return (
    <>
      <button
        className={`mb-4 flex items-center gap-3 pl-4 py-3 text-body whitespace-nowrap w-full rounded-lg hover:bg-hover hover:text-white-100 transition-colors disabled:text-primary-20 disabled:hover:!text-primary-20 disabled:hover:!bg-transparent border-b border-transparent text-primary-60 font-normal`}
        onClick={logout}
      >
        <LogoutIcon className="w-6 h-6" aria-hidden />
        <div className="items-center pr-2 w-full text-left whitespace-nowrap grow">
          <span>{i18n.t("userMenu.logout")}</span>
        </div>
      </button>
    </>
  );
};
