import { twMerge } from "tailwind-merge";

type CarouselProps = {
  items: {
    title: string;
    imageSrc: string;
  }[];
};

const colMap: Record<number, string> = {
  1: "grid-cols-[repeat(1,minmax(min(100%,540px),100%))]",
  2: "grid-cols-[repeat(2,minmax(min(100%,540px),100%))]",
  3: "grid-cols-[repeat(3,minmax(min(100%,540px),100%))]",
  4: "grid-cols-[repeat(4,minmax(min(100%,540px),100%))]",
  5: "grid-cols-[repeat(5,minmax(min(100%,540px),100%))]",
  6: "grid-cols-[repeat(6,minmax(min(100%,540px),100%))]",
} as const;

export function Carousel({ items = [] }: CarouselProps) {
  return (
    <div
      className={twMerge(
        "grid overflow-y-scroll rounded-lg scroll-hidden grid-flow-col gap-2 snap-mandatory",
        colMap[items.length]
      )}
    >
      {items.map((item) => {
        return (
          <div
            key={item.imageSrc}
            className="box-border snap-center snap-always"
          >
            <img
              role="presentation"
              src={item.imageSrc}
              className="object-cover w-full h-80 rounded-lg object-[center_center]"
              alt="Video carousel item"
            />
            <h1 className="mt-2 text-xl leading-[unset]">{item.title}</h1>
          </div>
        );
      })}
    </div>
  );
}
