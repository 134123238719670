import { useEffect, useState } from "react";

export enum DIRECTION {
  UP,
  DOWN,
}

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<DIRECTION>(
    DIRECTION.DOWN
  );
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    let prevScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > prevScrollY) {
        setScrollDirection(DIRECTION.DOWN);
      } else if (currentScrollY < prevScrollY) {
        setScrollDirection(DIRECTION.UP);
      }

      setIsAtTop(currentScrollY < 150);
      prevScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { scrollDirection, isAtTop };
};
