import { gql, useQuery } from "@apollo/client";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useDateRangeState } from "providers/DateRangeProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { useGetContactInfo } from "../initial/useGetContactInfo";
import { getFetchPolicyOptions } from "../utils";
import { ALLOCATION_BY_SECURITY_TYPE_FIELDS } from "./fragments";
import { AllPortfoliosHoldingsQuery, AllocationByType } from "./types";

// to distinct Contact analytics from Portfolio analytics in Contact version we set portfolio.id as portfolio.parentPortfolioId (line 32)
const HOLDINGS_QUERY = gql`
  ${ALLOCATION_BY_SECURITY_TYPE_FIELDS}
  query GetHoldings(
    $contactId: Long
    $locale: Locale
    $startDate: String
    $endDate: String
    $dataTimePeriodCode: String
    $timePeriodCodes: [String]
  ) {
    contact(id: $contactId) {
      id
      analytics(
        parameters: {
          startDate: $startDate
          endDate: $endDate
          paramsSet: {
            key: "allHoldingsByTypeByAssetGroup"
            groupCode: "ASSET"
            dataTimePeriodCode: $dataTimePeriodCode
            timePeriodCodes: $timePeriodCodes
            grouppedByProperties: [GROUP, SECTOR, SECURITY]
            includeData: false
            includeChildren: true
            drilldownEnabled: false
            limit: 0
            locale: $locale
          }
          includeDrilldownPositions: false
        }
      ) {
        allocationTopLevel: grouppedAnalytics(
          key: "allHoldingsByTypeByAssetGroup"
        ) {
          portfolio {
            id: parentPortfolioId
          }
          allAllocations: firstAnalysis {
            marketValue
            tradeAmountOrig
            shareOfTotal
            twr
          }
          ...AllocationBySecurityTypeFields
        }
      }
    }
  }
`;

export const useGetAllPortfoliosHoldings = () => {
  const { linkedContact } = useKeycloak();
  const { selectedContactId } = useGetContractIdData();
  const { i18n } = useModifiedTranslation();
  const { data: { portfoliosCurrency } = { portfoliosCurrency: "EUR" } } =
    useGetContactInfo(false, selectedContactId);
  const { dateRange, fetchPolicyString } = useDateRangeState();
  const { loading, error, data } = useQuery<AllPortfoliosHoldingsQuery>(
    HOLDINGS_QUERY,
    {
      variables: {
        contactId: selectedContactId || linkedContact,
        locale: i18n.language,
        ...dateRange,
      },
      ...getFetchPolicyOptions(
        `useGetAllPortfoliosHoldings.${
          selectedContactId || linkedContact
        }.${fetchPolicyString}`
      ),
    }
  );

  return {
    loading,
    error,
    data: data && {
      allocationByType: filterEmptyPositions(
        data.contact.analytics.allocationTopLevel.allocationByType
      ),
      allAllocations: data.contact.analytics.allocationTopLevel.allAllocations,
      // there is no way to get currency for analytics under contact context,
      // but all portfolios have same currency, so we use currency from first one
      currency: portfoliosCurrency,
    },
  };
};

const filterEmptyPositions = (allocationByType: AllocationByType[]) => {
  return allocationByType
    .map((group) => {
      const allocationsBySector = group.allocationsBySector
        .map((sector) => {
          const allocationsBySecurity = sector.allocationsBySecurity.filter(
            ({ figures }) => figures && figures.amount !== 0
          );
          return { ...sector, allocationsBySecurity };
        })
        .filter(filterEmptyParents);
      return { ...group, allocationsBySector };
    })
    .filter(filterEmptyParents);
};

const filterEmptyParents = ({
  figures,
}: {
  figures: {
    marketValue: number;
    tradeAmountOrig: number;
    shareOfTotal: number;
    amount: number;
    twr: number;
  };
}) => {
  return (
    figures &&
    figures.marketValue !== 0 &&
    figures.shareOfTotal !== 0 &&
    figures.tradeAmountOrig !== 0
  );
};
