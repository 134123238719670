import { PERIOD_RANGE } from "api/types";

export const toShortISOString = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const toLongISOString = (date: Date) => {
  const singleDigitMonth = date.getMonth() + 1 < 10;
  const singleDigitDate = date.getDate() < 10;
  return `${date.getFullYear()}-${singleDigitMonth ? 0 : ""}${
    date.getMonth() + 1
  }-${singleDigitDate ? 0 : ""}${date.getDate()}`;
};

export const startOfMonth = (date: Date) => {
  const resultDate = new Date(date);
  resultDate.setDate(1);
  resultDate.setHours(0, 0, 0, 0);

  return resultDate;
};

export const toLocaleShortDateString = (date: Date | number | string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const dateObj = new Date(date);
  return dateObj.toLocaleDateString("sv-SE", options);
};

export const startOfYear = (date: Date) => {
  const resultDate = new Date(date);
  resultDate.setMonth(0);
  resultDate.setDate(1);
  resultDate.setHours(0, 0, 0, 0);

  return resultDate;
};

export const dateFromYYYYMMDD = (stringDate: string) => {
  const dateArray = stringDate.split("-").map((period) => parseInt(period));
  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
};

export const dateToYYYYMMDD = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const isDateInRange = (
  date: Date,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const dateTime = date.getTime();
  const isOlder = !startDate || dateTime >= startDate.getTime();
  const isYounger = !endDate || dateTime <= endDate.getTime();

  return isOlder && isYounger;
};

const getQuarter = (d = new Date()) => Math.ceil((d.getMonth() + 1) / 3);
export const getDatesFromPeriod = (period: string) => {
  const today = new Date();
  let startDate = startOfYear(today),
    endDate = today;
  switch (period) {
    case PERIOD_RANGE.THIS_MONTH:
      startDate = startOfMonth(today);
      endDate = today;
      break;
    case PERIOD_RANGE.LAST_MONTH:
      startDate = startOfMonth(today);
      startDate.setMonth(startDate.getMonth() - 1);
      endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(endDate.getDate() - 1);
      break;
    case PERIOD_RANGE.THIS_QUARTER: {
      const quarter = getQuarter();
      const month = quarter * 3 - 2;
      startDate = new Date(today.getFullYear(), month - 1, 1);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_QUARTER: {
      const quarter = getQuarter();
      const month = quarter * 3 - 2;
      startDate = new Date(today.getFullYear(), month - 1, 1);
      startDate.setMonth(startDate.getMonth() - 3);
      endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 3);
      endDate.setDate(endDate.getDate() - 1);
      break;
    }
    case PERIOD_RANGE.THIS_HALF: {
      const half = Math.ceil((today.getMonth() + 1) / 6);
      const month = half * 6 - 5;
      startDate = new Date(today.getFullYear(), month - 1, 1);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_HALF: {
      const half = Math.ceil((today.getMonth() + 1) / 6);
      const month = half * 6 - 5;
      startDate = new Date(today.getFullYear(), month - 1, 1);
      startDate.setMonth(startDate.getMonth() - 6);
      endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 6);
      endDate.setDate(endDate.getDate() - 1);
      break;
    }
    case PERIOD_RANGE.THIS_YEAR: {
      startDate = startOfYear(today);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_YEAR: {
      startDate = startOfYear(today);
      startDate.setFullYear(startDate.getFullYear() - 1);
      endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate() - 1);
      break;
    }
    case PERIOD_RANGE.LAST_7_DAYS: {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_1_YEARS: {
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_3_YEARS: {
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 3);
      endDate = today;
      break;
    }
    case PERIOD_RANGE.LAST_5_YEARS: {
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 5);
      endDate = today;
      break;
    }
    default:
      break;
  }
  return { startDate, endDate };
};
