import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { Outlet } from "react-router-dom";
import { PortfolioNavigationHeader } from "./PortfolioNavigationHeader/PortfolioNavigationHeader";
import { PortfolioNavigationHeaderMobile } from "./PortfolioNavigationHeader/PortfolioNavigationHeaderMobile";

export const PortfolioNavigationHeaderLayout = () => {
  const isMobile = !useMatchesBreakpoint("lg");

  const Layout = isMobile
    ? PortfolioNavigationHeaderMobile
    : PortfolioNavigationHeader;

  return (
    <div className="flex flex-col h-full min-h-screen">
      <Layout />
      <Outlet />
    </div>
  );
};
