import { useQuery } from "@tanstack/react-query";
import { portalAPI } from "api/helper";
import { t } from "i18next";
import { useGetSessionToken } from "providers/SessionProvider";
import {
  InitalIssuersDetails,
  IssuerDetails,
  IssuersDetails,
  IssuersDetailsQuery,
} from "./types";

const query = `query Query {
  groups {
    publicInfo {
      website
    }
    externalId
  }
}
`;
export const getIssuersDetails = async (
  token: string | undefined
): Promise<IssuerDetails[]> => {
  try {
    const response = await portalAPI<IssuersDetailsQuery>({
      path: "graphql",
      query,
      token,
    });
    const data = response.data.groups;
    return data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(t("messages.failedToFetch", { error: error.message }));
    } else {
      throw new Error(t("messages.error"));
    }
  }
};

export const useGetIssuersDetails = () => {
  const token = useGetSessionToken();
  const { isLoading, error, data } = useQuery<IssuerDetails[], Error>(
    ["securityExtraDetails"],
    () => getIssuersDetails(token),
    { enabled: !!token, retry: false }
  );

  const issuersDetails = data?.map((issuerDetail: InitalIssuersDetails) => ({
    externalId: issuerDetail.externalId,
    website: issuerDetail.publicInfo.website,
  })) as IssuersDetails[];

  return {
    loading: isLoading,
    error,
    data: issuersDetails,
  };
};
