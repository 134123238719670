import { gql, useQuery } from "@apollo/client";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { ContactTagsQuery } from "./types";

const PAGE_TAG_PREFIX = "Finansportalen" as const;
const CONTACT_TAGS_QUERY = gql`
  query ContactTags($contactId: Long) {
    contact(id: $contactId) {
      id
      tagsAsSet
    }
  }
`;

export const useGetContactPages = () => {
  const { linkedContact } = useKeycloak();
  const { selectedContactId } = useGetContractIdData();

  const { loading, error, data } = useQuery<ContactTagsQuery>(
    CONTACT_TAGS_QUERY,
    {
      variables: {
        contactId: selectedContactId || linkedContact,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    loading,
    error,
    data: data?.contact.tagsAsSet?.filter((tag) =>
      tag.includes(PAGE_TAG_PREFIX)
    ),
  };
};
