import { ReactNode } from "react";
import classNames from "classnames";

interface DataRowProps {
  label: string | ReactNode;
  value: ReactNode;
  onClick?: () => void;
}

export const DataRow = ({ label, value, onClick }: DataRowProps) => (
  <div
    className={classNames("flex justify-between items-center py-3", {
      "cursor-pointer": onClick && value,
    })}
    onClick={onClick}
  >
    <div className="text-body-small font-normal text-primry-100">{label}</div>
    <div className="text-headline-4 font-semibold text-right text-primary-100">
      {value}
    </div>
  </div>
);
