import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useNavTabProvider, ACTIONS } from "providers/NavTabProvider";
import { useLocation, useNavigate } from "react-router";
import { NavTabPath } from "./types";

interface useNavTabProps {
  navTabPaths: NavTabPath[];
}

export const useNavTab = ({ navTabPaths }: useNavTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const activePath = paths.includes("portfolio") ? paths[3] : paths[1];
  const currentTabIndex = getActiveTabIndex(navTabPaths, activePath);
  const isMobile = !useMatchesBreakpoint("lg");
  const { dispatch } = useNavTabProvider();

  // const {}
  const handleMenuItemClick = (index: number) => {
    if (isMobile) {
      dispatch({
        type: ACTIONS.CLOSE,
      });
    }
    if (activePath !== navTabPaths[index].path) {
      navigate(`../${navTabPaths[index].path}`);
      return;
    }

    if (
      activePath === navTabPaths[index].path &&
      !paths.includes("portfolio")
    ) {
      navigate(`/${navTabPaths[index].path}`);
      return;
    }
    if (activePath === navTabPaths[index].path && paths.includes("portfolio")) {
      const portfolio = paths[2];
      navigate(`/portfolio/${portfolio}/${navTabPaths[index].path}`);
      return;
    }
  };

  return {
    groupProps: {
      selectedIndex: currentTabIndex,
    },
    handleMenuItemClick: handleMenuItemClick,
  };
};

const getActiveTabIndex = (
  routes: NavTabPath[],
  activePath: string | undefined
) => routes.findIndex((route) => route.path === activePath);
