import { useCallback, useEffect, useRef, useState } from "react";

export const useContentPosition = ({
  x,
  y,
}: {
  x: number | undefined;
  y: number | undefined;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalPosition, setModalPosition] = useState({
    left: x,
    top: y,
  });

  useEffect(() => {
    setModalPosition({ left: x, top: y });
  }, [x, y]);

  const checkModalPosition = useCallback(() => {
    const timeout = setTimeout(() => {
      if (!x || !y) return;
      if (modalRef.current) {
        const modalRect = modalRef.current.getBoundingClientRect();
        const { width, height } = modalRect;

        const screenWidth = window.innerWidth;
        setModalPosition((prev) => ({
          ...prev,
          top: y - height,
          left: x - width / 2,
        }));
        if (x + width > screenWidth) {
          setModalPosition((prev) => ({
            ...prev,
            left: x - width,
          }));
        }
        if (height > y) {
          setModalPosition((prev) => ({
            ...prev,
            top: (height - y) / 2,
          }));
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [x, y]);

  useEffect(() => {
    checkModalPosition();
  }, [checkModalPosition]);

  return { modalPosition, modalRef, checkModalPosition };
};
