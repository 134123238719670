import { z } from "zod";
import {
  HOLDINGS_SETTINGS,
  VALID_ASSET_TYPES,
} from "./hooks/useCategoryIssuerMap";

const reqiredString = z.string().min(1, "Required");
export const MAX_FILE_SIZE = 1024 * 1024 * 20;

export const acceptedFormats = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "text/plain": [".txt", ".text"],
};

export const contactMessageSchema = z.object({
  senderName: reqiredString,
  senderEmail: reqiredString,
  subject: reqiredString,
  text: reqiredString,
  files: z
    .array(z.any())
    .optional()
    .refine(
      (files) =>
        !files ||
        files.reduce((acc, file) => acc + file.src.size, 0) < MAX_FILE_SIZE,
      {
        message: "contactModal.errors.totalSize",
      }
    )
    .refine(
      (files) => {
        return (
          !files ||
          files.every((file) => {
            const fileExtension = `.${file.src.name.split(".").pop()}`;
            const fileMimeType = file.src.type;
            return Object.entries(acceptedFormats).some(
              ([mimeType, extensions]) => {
                return (
                  mimeType === fileMimeType &&
                  extensions.includes(fileExtension)
                );
              }
            );
          })
        );
      },
      {
        message: "contactModal.errors.fileFormat",
      }
    ),
});

export const contactIssuersSchema = z
  .object({
    holdingsSetting: z.object({
      id: z.nativeEnum(HOLDINGS_SETTINGS),
      label: z.string(),
    }),
    assetType: z.object({
      id: z.nativeEnum(VALID_ASSET_TYPES),
      label: z.string(),
    }),
    externalIds: z.array(z.string()).min(1),
  })
  .and(contactMessageSchema);
