/* eslint-disable @typescript-eslint/no-explicit-any */
import { Allocation } from "api/securities/types";
import { getNameFromBackendTranslations } from "./transactions";

export const getSectorFromAllocations = (
  allocations: Allocation[],
  locale: string,
  assetType = "ASSET"
) => {
  const sector = allocations.find(
    (allocation) => allocation.group.code === assetType
  )?.sector;

  if (!sector) return undefined;
  const { namesAsMap, name: typeName } = sector;
  return getNameFromBackendTranslations(typeName, locale, namesAsMap);
};

export const getSectorIdFromAllocations = (
  allocations: Allocation[],
  assetType = "ASSET"
) => {
  const code = allocations.find(
    (allocation) => allocation.group.code === assetType
  )?.sector.code;

  return code;
};

export const getPathToValue = (
  allocations: Allocation[],
  locale: string,
  assetType = "ASSET"
): string[] => {
  let path: string[] = [];
  let found = false;
  let sector = allocations.find(
    (allocation) => allocation.group.code === assetType
  )?.sector;

  const traverseObject = (obj: any, currentPath: string[]): void => {
    for (const key in obj) {
      const value = obj[key];

      if (value === sector) {
        path = currentPath.concat(key);
        found = true;
        return;
      }

      if (typeof value === "object" && value !== null) {
        traverseObject(value, currentPath.concat(key));
      }
    }
  };

  for (let i = 0; i < allocations.length; i++) {
    const allocation = allocations[i];
    const allocationPath = ["allocations", i.toString(), "sector"];

    if (allocation.group.code === assetType) {
      path = allocationPath;
      sector = allocation.sector;
      found = true;
      break;
    }

    traverseObject(allocation, allocationPath);
    if (found) break;
  }

  if (found && sector) {
    const { namesAsMap, name: typeName } = sector;
    const translatedName = getNameFromBackendTranslations(
      typeName,
      locale,
      namesAsMap
    );
    if (translatedName) {
      const localePath = ["namesAsMap", locale.replace("-", "_")];
      path.push(...localePath);
    } else {
      path.push("name");
    }
  }

  return path;
};
