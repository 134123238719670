import { HTMLAttributes } from "react";
import { DownloadableDocument } from "components";

interface DocumentRowProps extends HTMLAttributes<HTMLAnchorElement> {
  label: string;
  url: string;
}

export const DocumentRow = (props: DocumentRowProps) => {
  return <DownloadableDocument {...props} />;
};
