import { useCallback, useState } from "react";
import { createPopper } from "@popperjs/core";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import ReactDatePicker, {
  DatePickerProps as ReactDatePickerProps,
} from "react-date-picker/dist/entry.nostyle";
import "./DatePicker.css";
import "./Calendar.css";

interface DatePickerProps extends ReactDatePickerProps {
  label?: string;
}

// TODO: keep eye on react-date-picker they plan to add portalContainer prop which will simplify calendar positioning
export const DatePicker = ({ label, ...props }: DatePickerProps) => {
  const { i18n } = useModifiedTranslation();
  const { positionedElementRefCallback, targetRefCallback } =
    usePositionElementToOtherElement();

  return (
    <div className="flex flex-col gap-0 w-full" ref={targetRefCallback}>
      {label && (
        <label className="mb-1 text-body-small text-primary-80">{label}</label>
      )}
      <ReactDatePicker
        calendarIcon={
          <span className="text-2xl text-secondary-100 mgc_calendar_line"></span>
        }
        clearIcon={null}
        className="p-2 md:py-2 md:px-3.5 text-body-small leading-tight text-primary-100 bg-transparent rounded border border-primary-20 h-[46px]"
        locale={i18n.language}
        inputRef={positionedElementRefCallback}
        {...props}
      />
    </div>
  );
};

const usePositionElementToOtherElement = () => {
  const [targetNode, setTargetNode] = useState<HTMLElement>();

  const targetRefCallback = useCallback((node: HTMLElement | null) => {
    if (!node) {
      return;
    }
    setTargetNode(node);
  }, []);

  const positionedElementRefCallback = useCallback(
    (node: HTMLElement | null) => {
      if (!node || !targetNode) {
        return;
      }
      createPopper(targetNode, node, {
        placement: "bottom-start",
      });
    },
    [targetNode]
  );

  return {
    positionedElementRefCallback,
    targetRefCallback,
  };
};
