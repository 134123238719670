import {
  Children,
  cloneElement,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from "react";
import classNames from "classnames";

interface GridProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  firstChildClassName?: string;
  secondChildClassName?: string;
  secondLastChildClassName?: string;
  lastChildClassName?: string;
  middleChildClassName?: string;
}

export const Grid = ({
  children,
  className,
  firstChildClassName = "",
  secondChildClassName = "",
  secondLastChildClassName = "",
  lastChildClassName = "",
  middleChildClassName = "",
  ...rest
}: GridProps) => {
  const ChildrenArray = Children.toArray(children);

  // `

  return (
    <>
      {ChildrenArray.map((child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...rest,
            className: `${child.props.className ?? ""} ${className} ${
              index === 0 ? firstChildClassName : ""
            } ${
              index === ChildrenArray.length - 2
                ? secondLastChildClassName
                : index === ChildrenArray.length - 1
                ? lastChildClassName
                : ""
            } ${index === 1 ? secondChildClassName : ""} ${
              index !== 0 && index !== 1 && index !== ChildrenArray.length - 1
                ? middleChildClassName
                : ""
            }`,
          });
        }
        return child;
      })}
    </>
  );
};
// classNames(child.props.className, className, {
//   index === 0: firstChildClassName,
//   index === 1: secondChildClassName,
//   index === ChildrenArray.length - 1: lastChildClassName,
//   index !== 0 && index !== 1 && index !== ChildrenArray.length - 1: middleChildClassName,
// })
interface GridHeaderProps {
  children: ReactNode;
  bg?: string;
  firstChildClassName?: string;
  secondChildClassName?: string;
  middleChildClassName?: string;
  secondLastChildClassName?: string;
  lastChildClassName?: string;
  className?: string;
}

const GridHeader = ({
  children,
  bg = "gray-100",
  firstChildClassName,
  secondChildClassName,
  middleChildClassName,
  secondLastChildClassName,
  lastChildClassName,
  className,
}: GridHeaderProps) => (
  <Grid
    className={classNames(
      `font-normal text-primary-60 bg-${bg}`,
      // { "text-white-60": isDark },
      className
    )}
    firstChildClassName={firstChildClassName}
    secondChildClassName={secondChildClassName}
    middleChildClassName={middleChildClassName}
    secondLastChildClassName={secondLastChildClassName}
    lastChildClassName={lastChildClassName}
  >
    {children}
  </Grid>
);
Grid.Header = GridHeader;

const GridRow = ({
  children,
  onClick,
  firstChildClassName,
  secondChildClassName,
  middleChildClassName,
  secondLastChildClassName,
  lastChildClassName,
  className,
  ...rest
}: GridProps) => (
  <Grid
    firstChildClassName={firstChildClassName}
    secondChildClassName={secondChildClassName}
    middleChildClassName={middleChildClassName}
    secondLastChildClassName={secondLastChildClassName}
    lastChildClassName={lastChildClassName}
    onClick={onClick}
    className={
      (className ? className : "") + (onClick ? " cursor-pointer" : "")
    }
    {...rest}
  >
    {children}
  </Grid>
);
Grid.Row = GridRow;
