import { ReactNode } from "react";
import classNames from "classnames";
interface CardProps {
  children: ReactNode;
  header?: ReactNode;
  headerColor?: "primary" | "transparent";
  onClick?: () => void;
  visible?: boolean;
  bg?: string;
  fullHeight?: boolean;
  size?: "small" | "large";
  grow?: boolean;
  id?: string;
}

export const Card = ({
  children,
  header,
  headerColor = "primary",
  bg = "white-100",
  fullHeight = false,
  visible,
  onClick,
  size = "large",
  grow = false,
  id,
}: CardProps) => (
  <div
    id={id}
    className={classNames(
      `flex overflow-hidden flex-col bg-${bg} rounded transition-all`,
      {
        "cursor-pointer": !!onClick,
        "overflow-visible": visible,
        "h-full": fullHeight,
        "flex-grow": grow,
      }
    )}
    onClick={onClick}
  >
    {header && (
      <div
        className={classNames(
          "p-4 md:px-2 text-headline-3 font-semibold text-white-100 bg-secondary-100 rounded-t",
          {
            "text-headline-4 px-4 py-2": size === "small",
            "bg-white-100 !text-primary-100": headerColor === "transparent",
          }
        )}
      >
        {header}
      </div>
    )}
    {children}
  </div>
);
