export const round = (number: number, decimalPlaces: number) =>
  Math.round((number + Number.EPSILON) * Math.pow(10, decimalPlaces)) /
  Math.pow(10, decimalPlaces);

export const getPercentageOf = ({
  marketValueChange,
  marketValueStart,
}: {
  marketValueChange: number;
  marketValueStart: number;
}) => {
  return marketValueChange / marketValueStart;
};

export const convertToByte = (megabytes: number) => {
  return megabytes * 1024 * 1024;
};

export const convertToMB = (bytes: number) => {
  return bytes / 1024 / 1024;
};
export const roundDown = (number: number, decimalPlaces: number): number =>
  Math.floor(number * Math.pow(10, decimalPlaces)) /
  Math.pow(10, decimalPlaces);
