import { type ReactNode } from "react";
type QuoteProps = {
  attribution: ReactNode;
  content: ReactNode;
};

export function Quote({ attribution, content }: QuoteProps) {
  return (
    // eslint-disable-next-line no-octal-escape
    <div className="relative after:absolute after:top-0 after:left-4 pt-1 pr-3 pb-4 pl-12 rounded-md bg-[#f3f5f6] after:content-['\201C'] after:text-[4rem]">
      <div style={{ fontStyle: "italic", color: "#4A5568" }}>{content}</div>
      <div style={{ fontWeight: "bold", color: "#47546b" }}>
        — {attribution}
      </div>
    </div>
  );
}
