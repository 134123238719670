import { issuerLogos } from "components/IssuerLogo/IssuerLogo";
import { twMerge } from "tailwind-merge";
import { SecurityInfo } from "./hooks/useCategoryIssuerMap";

interface IssuerSelectProps {
  onSelect: (codes: string[]) => void;
  selected: string[];
  issuers: SecurityInfo[];
}

export const IssuerSelect = ({
  issuers = [],
  selected,
  onSelect,
}: IssuerSelectProps) => {
  const handleClick = (code: string) => {
    let newSelected;
    if (selected.includes(code)) {
      newSelected = selected.filter((id) => id !== code);
    } else {
      newSelected = [...selected, code];
    }

    onSelect(newSelected);
  };

  if (issuers.length === 0) {
    return (
      <div className="w-full text-center">
        <span className="text-sm text-primary-40">
          Inga fondbolag som matchar din filtrering
        </span>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      {issuers.map((issuer) => (
        <Box
          key={issuer.code}
          {...issuer}
          isSelected={selected.includes(issuer.code)}
          onClick={() => handleClick(issuer.code)}
        />
      ))}
    </div>
  );
};

interface BoxProps {
  name: string;
  code: string;
  isSelected: boolean;
  onClick: () => void;
}
const Box = ({ name, code, isSelected, onClick }: BoxProps) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={twMerge(
        "h-auto py-0.5 border flex-row rounded bg-white-100 border-primary-20 flex items-center justify-start  transition-colors",
        isSelected && "bg-secondary-5 border-secondary-100"
      )}
    >
      <img
        src={issuerLogos[Number(code)].icon}
        alt={`${name} logo`}
        className={`object-contain w-10 p-1 pointer-events-none`}
      />
      <span className="text-sm">{name}</span>
    </button>
  );
};
