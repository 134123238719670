import { Fragment, ReactNode } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import classNames from "classnames";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useController, UseControllerProps } from "react-hook-form";
import { FieldValues } from "react-hook-form";
import { usePopper } from "../../hooks/usePopper";
export interface Option {
  id: number | string | null;
  label: string;
  OptionComponent?: ReactNode;
}

interface FormSelectProps<T> {
  options: T[];
  label?: string;
  text?: string;
  fontWeight?: string;
  fontColor?: string;
}

export const FormSelect = <
  TOption extends Option,
  TFormValues extends FieldValues = FieldValues
>(
  props: FormSelectProps<TOption> & UseControllerProps<TFormValues>
) => {
  const { t } = useModifiedTranslation();
  const {
    options,
    label,
    text = "lg",
    fontWeight = "bold",
    fontColor = "primary-100",
  } = props;
  const {
    field: { value, onChange },
  } = useController(props);
  const [trigger, container] = usePopper({
    placement: "bottom-start",
    modifiers: [
      {
        name: "sameWidth",
        enabled: true,
        phase: "beforeWrite",
        fn({ state }) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        requires: ["computeStyles"],
        effect: ({ state }) => {
          if (state.elements.reference instanceof Element) {
            // fake scroll event to recalculate popper position in case there is animation
            setTimeout(() => {
              dispatchEvent(new CustomEvent("scroll"));
            }, 500);
            state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
          }
        },
      },
    ],
  });
  return (
    <Listbox as="div" value={value} onChange={onChange}>
      {label && (
        <Listbox.Label className="text-sm font-normal">{label}</Listbox.Label>
      )}
      <Listbox.Button
        className={classNames(
          `flex gap-2 items-center py-2.5 px-4 w-full font-${fontWeight} text-${fontColor} bg-transparent rounded border border-primary-20 text-${text}`
        )}
        ref={trigger}
      >
        <div className="box-border flex-1 content-start leading-none text-left">
          {value ? value.label : t("component.select.placeholder")}
        </div>
        <ChevronDown className="w-[20px] h-[20px]" />
      </Listbox.Button>
      <div ref={container}>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Listbox.Options className="z-30 py-1 text-base list-none bg-white-100 rounded divide-y divide-primary-10 shadow">
            {options.map((option) => (
              <Listbox.Option key={option.id} value={option} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={classNames(
                      "block py-2 px-4 text-sm text-primary-70 dark:text-gray-200 cursor-pointer z-40",
                      {
                        "dark:text-white-100 bg-secondary-20 dark:bg-gray-600":
                          active,
                        "font-bold": selected,
                      }
                    )}
                  >
                    {option.OptionComponent ?? option.label}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
