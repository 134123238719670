import { Fragment, ComponentProps } from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { twMerge } from "tailwind-merge";
import { Hero, YouTubeVideo, Carousel, Quote } from "./components";
type CustomRendererProps = ComponentProps<typeof DocumentRenderer>;

export const WYSIWYG = ({ document }: CustomRendererProps) => {
  const defaultElementRenderers: CustomRendererProps["renderers"] = {
    block: {
      // all custom components are block components
      // so they will be wrapped with a <div /> by default
      // we can override that to whatever wrapper we want
      // for eg. using React.Fragment wraps the component with nothing
      block: Fragment, // FIXME
      // customise blockquote elements with your own styles
      blockquote({ children }) {
        return (
          <blockquote className="py-2 px-8 m-0 border-l-2">
            {children}
          </blockquote>
        );
      },
      layout(props) {
        const { children, layout } = props;
        const gridCols = `grid-cols-${layout.length}`;
        return (
          <div className={twMerge("grid gap-4 border-dotted", gridCols)}>
            {children.map((child) => (
              <div key={child.key}>{child}</div>
            ))}
          </div>
        );
      },
      code({ children }) {
        return (
          <pre className="break-words bg-primary-100 rounded box-decoration-clone scroll-hidden">
            {children}
          </pre>
        );
      },

      // and more - check out the types to see all available block elements
    },
    inline: {
      bold: ({ children }) => {
        return <strong style={{ color: "#363945" }}>{children}</strong>;
      },
      // inline code ` `
      code: ({ children }) => {
        return <code>{children}</code>;
      },
      link: ({ children, href }) => {
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },

      // and more - check out the types to see all available inline elements
    },
  };

  const customComponentRenderers: CustomRendererProps["componentBlocks"] = {
    hero: (props) => {
      return <Hero {...props} />;
    },
    youtubeVideo: (props) => {
      return <YouTubeVideo {...props} />;
    },
    carousel: (props) => {
      return <Carousel {...props} />;
    },
    quote: (props) => {
      return <Quote {...props} />;
    },
  };

  return (
    <DocumentRenderer
      renderers={defaultElementRenderers}
      componentBlocks={customComponentRenderers}
      document={document}
    />
  );
};
