import { Suspense } from "react";
import { LoadingIndicator } from "components";
import { PageLayout } from "layouts/PageLayout/PageLayout";
import { Outlet } from "react-router-dom";
import { NavTab } from "./NavTab/NavTab";
import { NavTabPath } from "./NavTab/types";
import { useNavTab } from "./NavTab/useNavTab";

interface NavTabTemplateLayoutProps {
  routes: NavTabPath[];
}

export const NavTabLayout = ({ routes }: NavTabTemplateLayoutProps) => {
  const { handleMenuItemClick, groupProps } = useNavTab({
    navTabPaths: routes,
  });

  return (
    <div className="flex flex-row flex-1 items-stretch">
      <NavTab.Group {...groupProps}>
        <NavTab.List>
          {routes.map((route, index) => (
            <NavTab.NavTab
              disabled={route.disabled}
              key={`NavTab_${index}`}
              onClick={() => handleMenuItemClick(index)}
            >
              <div className="flex gap-3 items-center">
                {route.icon} <span>{route.tabLabel}</span>
                {route.notification && !route.disabled && (
                  <div className="w-2 bg-secondary-100 rounded-full aspect-square ml-[-8px] mt-[-10px]" />
                )}
              </div>
            </NavTab.NavTab>
          ))}
        </NavTab.List>
      </NavTab.Group>
      <PageLayout>
        <Suspense fallback={<LoadingIndicator center />}>
          <Outlet />
        </Suspense>
      </PageLayout>
    </div>
  );
};
