import { ReactNode } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { NoPortfolios } from "./components/NoPortfolios";

interface PortfolioGuardProps {
  children: ReactNode;
  authorized?: boolean;
}

export const PortfolioGuard = ({
  children,
  authorized,
}: PortfolioGuardProps) => {
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  // if (!authorized) {
  //   return <Navigate to="/" />;
  // }

  if (portfolios.length === 0) {
    return (
      <>
        <NoPortfolios />
      </>
    );
  }

  return <>{children}</>;
};
