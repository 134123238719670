import classNames from "classnames";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
interface ILogo {
  large?: boolean;
}
export const Logo = (props: ILogo) => {
  const { large } = props;
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  return (
    <div
      className={classNames("rounded cursor-pointer", {
        "w-10 h-10 ": !large,
      })}
      onClick={() => navigate(portfolioId ? `/portfolio/${portfolioId}/` : "/")}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo${large && "_large"}.svg`}
        alt="logo"
      />
    </div>
  );
};
