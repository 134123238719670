import {
  HTMLAttributes,
  ReactNode,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import { Center } from "components/Center/Center";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { t } from "i18next";

interface StatusComponentProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const StatusComponent = ({
  children,
  ...rest
}: StatusComponentProps) => {
  const ChildrenArray = Children.toArray(children);
  return (
    <>
      {ChildrenArray.map((child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...rest,
          });
        }
        return child;
      })}
    </>
  );
};

const LoadingShimmerTable = () => {
  return (
    <div className="relative">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-0.5">
          <div className="w-full h-6 bg-secondary-100/10"></div>
          <div className="w-full h-6 bg-secondary-100/5"></div>
          <div className="w-full h-6 bg-secondary-100/10"></div>
        </div>
      </div>
    </div>
  );
};

const LoadingShimmerPortfolioDetailsCard = () => {
  return (
    <div className="flex justify-between">
      <div className="overflow-hidden relative w-[200px]">
        <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_1s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className="space-y-0.5">
            <div className="w-full h-5 bg-secondary-100/10"></div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden relative w-[80px]">
        <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_1s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className="space-y-0.5">
            <div className="w-full h-5 bg-secondary-100/10"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingShimmerBookkeeeping = () => {
  return (
    <div className="overflow-hidden relative">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-0.5">
          <div className="w-full h-14 bg-secondary-100/10"></div>
          <div className="w-full h-14 bg-secondary-100/5"></div>
          <div className="w-full h-14 bg-secondary-100/10"></div>
          <div className="w-full h-14 bg-secondary-100/5"></div>
          <div className="w-full h-14 bg-secondary-100/10"></div>
          <div className="w-full h-14 bg-secondary-100/5"></div>
        </div>
      </div>
    </div>
  );
};
const LoadingShimmerTransactions = () => {
  return (
    <div className="overflow-hidden relative">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-0.5">
          <div className="w-full h-14 bg-secondary-100/10"></div>
          <div className="w-full h-14 bg-secondary-100/5"></div>
          <div className="w-full h-14 bg-secondary-100/10"></div>
        </div>
      </div>
    </div>
  );
};
const LoadingShimmerSecurities = () => {
  return (
    <div className="overflow-hidden relative">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-0.5">
          <div className="w-full h-14 bg-secondary-100/10"></div>
          <div className="w-full h-14 bg-secondary-100/5"></div>
          <div className="w-full h-14 bg-secondary-100/10"></div>
        </div>
      </div>
    </div>
  );
};

const LoadingShimmerFeed = () => {
  return (
    <div className="overflow-hidden relative w-[650px]">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-5">
          <div className="flex flex-col py-12 px-20 space-y-2 w-full rounded-lg h-[450px] bg-secondary-100/10">
            <div className="h-6 rounded-sm w-[220px] bg-secondary-100/5"></div>
            <div className="h-16 rounded-sm w-[420px] bg-secondary-100/5"></div>
            <div className="w-full rounded-sm h-[350px] bg-secondary-100/5"></div>
          </div>
          <div className="flex flex-col py-12 px-20 space-y-2 w-full rounded-lg h-[450px] bg-secondary-100/10">
            <div className="h-6 rounded-sm w-[220px] bg-secondary-100/5"></div>
            <div className="h-16 rounded-sm w-[420px] bg-secondary-100/5"></div>
            <div className="w-full rounded-sm h-[350px] bg-secondary-100/5"></div>
          </div>
          {/* <div className="w-full h-14 bg-secondary-100/5"></div>
          <div className="w-full h-14 bg-secondary-100/10"></div> */}
        </div>
      </div>
    </div>
  );
};

const LoadingShimmerPost = () => {
  return (
    <div className="overflow-hidden relative w-[800px]">
      <div className="flex flex-col justify-center items-center mb-10 bg-transparent">
        <div className="overflow-hidden before:absolute relative before:inset-0 mb-1.5 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent rounded before:-translate-x-full h-[17.5px] isolatemb-2 3 w-[120px] bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className=" bg-secondary-100/5"></div>
        </div>
        <div className="overflow-hidden before:absolute relative before:inset-0 mb-8 space-y-5 h-12 before:bg-gradient-to-r before:from-transparent before:to-transparent rounded before:-translate-x-full isolatemb-2 3 w-[420px] bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className=" bg-secondary-100/5"></div>
        </div>
        {/* <div className="overflow-hidden before:absolute relative before:inset-0 space-y-5 h-12 before:bg-gradient-to-r before:from-transparent before:to-transparent rounded before:-translate-x-full isolatemb-2 3 w-[220px] bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className=" bg-secondary-100/5"></div>
        </div> */}
        <div className="overflow-hidden before:absolute relative before:inset-0 space-y-5 h-8 before:bg-gradient-to-r before:from-transparent before:to-transparent rounded before:-translate-x-full isolatemb-2 3 w-[120px] bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
          <div className=" bg-secondary-100/5"></div>
        </div>
      </div>
      <div className="isolate overflow-hidden before:absolute relative before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent rounded-lg before:-translate-x-full h-fit bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="space-y-5">
          <div className="flex flex-col py-12 px-20 space-y-2 w-full rounded-lg h-[450px] bg-secondary-100/10">
            <div className="h-6 rounded-sm w-[220px] bg-secondary-100/5"></div>
            <div className="h-16 rounded-sm w-[420px] bg-secondary-100/5"></div>
            <div className="w-full rounded-sm h-[350px] bg-secondary-100/5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingShimerSecuritiesAmount = () => {
  return (
    <div className="overflow-hidden relative">
      <div className="isolate overflow-hidden before:absolute before:inset-0 space-y-5 before:bg-gradient-to-r before:from-transparent before:to-transparent before:-translate-x-full irelative bg-white/5 shadow-black/5 before:animate-[shimmer_2s_infinite] before:border-primary-100/10 before:via-primary-100/10">
        <div className="w-full h-6 bg-secondary-100/10"></div>
      </div>
    </div>
  );
};

interface LoadingSpinnerProps {
  center?: boolean;
}
const LoadingSpinner = ({ center }: LoadingSpinnerProps) => {
  return (
    <div className="py-5">
      <LoadingIndicator center={center} />
    </div>
  );
};
const Error = () => {
  return (
    <div className="py-5">
      <Center>
        <span className="text-body-small">
          {t("messages.queryErrorWarning")}
        </span>
      </Center>
    </div>
  );
};

StatusComponent.LoadingShimmerTable = LoadingShimmerTable;
StatusComponent.LoadingShimmerPortfolioDetailsCard =
  LoadingShimmerPortfolioDetailsCard;
StatusComponent.LoadingSpinner = LoadingSpinner;
StatusComponent.LoadingShimmerBookkeeeping = LoadingShimmerBookkeeeping;
StatusComponent.LoadingShimmerTransactions = LoadingShimmerTransactions;
StatusComponent.LoadingShimmerSecurities = LoadingShimmerSecurities;
StatusComponent.LoadingShimerSecuritiesAmount = LoadingShimerSecuritiesAmount;
StatusComponent.LoadingShimmerFeed = LoadingShimmerFeed;
StatusComponent.LoadingShimmerPost = LoadingShimmerPost;
StatusComponent.Error = Error;
