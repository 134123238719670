import { useMutation, gql } from "@apollo/client";

interface MutateHasAcceptedTOC {
  loadPreference: string;
}

const MUTATE_HAS_ACCEPTED_QUERY = gql`
  mutation MutateHasAccepted($value: String!) {
    savePreference(prefValue: $value, prefKey: "termsOfCondition")
  }
`;

interface UseMutateHasAcceptedTOCProps {
  onSuccess: () => void;
}

export const useMutateHasAcceptedTOC = ({
  onSuccess,
}: UseMutateHasAcceptedTOCProps) => {
  const [updateHasAccepted, { loading, error, reset }] = useMutation<{
    updateHasAccepted: MutateHasAcceptedTOC;
  }>(MUTATE_HAS_ACCEPTED_QUERY, {
    onCompleted: onSuccess,
    variables: {
      value: JSON.stringify({
        acceptedVersion: process.env.REACT_APP_TERMS_USE_VERSION,
        timeStamp: new Date(),
      }),
    },
  });

  return {
    loading,
    error,
    reset,
    updateHasAccepted,
  };
};
