import { MutableRefObject, useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostEmailIssuers } from "api/email/usePostEmailIssuers";
import { Button, FormSelect, Input } from "components";
// import { FileUpload } from "components/FileUpload/FileUpload";
import { Textarea } from "components/Textarea/Textarea";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { t } from "i18next";
import { useKeycloak } from "providers/KeycloakProvider";
import { ErrorCode } from "react-dropzone";
import { Controller, /*useFieldArray, */ useForm } from "react-hook-form";
// import { convertToMB } from "utils/number";
import {
  // MAX_FILE_SIZE,
  // acceptedFormats,
  contactIssuersSchema,
} from "./ContactSchemas";
import {
  HOLDINGS_SETTINGS,
  VALID_ASSET_TYPES,
  useGetRelevantIssuers,
} from "./hooks/useCategoryIssuerMap";
import { IssuerSelect } from "./IssuerSelect";
import { Radio } from "./Select";

interface EmailIssuersModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

export interface Option<T> {
  id: T;
  label: string;
}

export type FieldsFile = {
  src: File;
  error?: string;
};

export interface issuerFormData {
  holdingsSetting: Option<HOLDINGS_SETTINGS>;
  assetType: Option<VALID_ASSET_TYPES>;
  issuer: string;
  text: string;
  subject: string;
  externalIds: string[];
  senderName: string;
  senderEmail: string;
  files?: FieldsFile[];
}

const LineBreakText = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center mt-6 mb-1">
      <div className=" w-full bg-primary-20 h-[1px]"></div>
      <span className="mx-1 text-sm text-primary-40 whitespace-nowrap">
        {text}
      </span>
      <div className=" w-full bg-primary-20 h-[1px]"></div>
    </div>
  );
};

export const invalidFileMsg = (errorCode: string) => {
  switch (errorCode) {
    case ErrorCode.FileInvalidType:
      return `${t("dropzone.errors.file-invalid-type")}`;
    case ErrorCode.FileTooLarge:
      return `${t("dropzone.errors.file-too-large")}`;
    case ErrorCode.TooManyFiles:
      return `${t("dropzone.errors.too-many-files")}`;
    default:
      return null;
  }
};

const MailIssuersModalContent = ({
  modalInitialFocusRef,
  onClose,
}: EmailIssuersModalProps) => {
  const { t } = useModifiedTranslation();
  const { sendEmail } = usePostEmailIssuers();
  const { userProfile } = useKeycloak();

  const holdingsOptions: Option<HOLDINGS_SETTINGS>[] = [
    {
      id: HOLDINGS_SETTINGS.PORTFOLIO,
      label: t("contactModal.fields.holdings"),
    },
    {
      id: HOLDINGS_SETTINGS.ALL,
      label: t("contactModal.fields.allFunds"),
    },
  ];

  const assetTypeOptions: Option<VALID_ASSET_TYPES>[] = [
    {
      id: VALID_ASSET_TYPES.ALL,
      label: t("assetTypes.all"),
    },
    {
      id: VALID_ASSET_TYPES.SWEDEN_STOCKS,
      label: t("assetTypes.swedishStocks"),
    },
    {
      id: VALID_ASSET_TYPES.GLOBAL_STOCKS,
      label: t("assetTypes.globalStocks"),
    },
    {
      id: VALID_ASSET_TYPES.ALTERNATIVE,
      label: t("assetTypes.alternativeInvestments"),
    },
    {
      id: VALID_ASSET_TYPES.NOMINAL,
      label: t("assetTypes.nominalInterestRate"),
    },
    {
      id: VALID_ASSET_TYPES.REALA,
      label: t("assetTypes.realInterestRates"),
    },
  ];

  const resolver = zodResolver(contactIssuersSchema);

  const {
    handleSubmit,
    formState: { isValid },
    watch,
    control,
    register,
  } = useForm<issuerFormData>({
    resolver: resolver,
    mode: "all",
    defaultValues: {
      assetType: assetTypeOptions[0],
      text: "",
      subject: "",
      externalIds: [],
      senderName: `${userProfile?.firstName} ${userProfile?.lastName}`,
      senderEmail: userProfile?.email,
      files: [],
    },
  });

  const holdingsSetting = watch("holdingsSetting")?.id;
  const assetTypeSetting = watch("assetType")?.id;
  const issuerOptions = useGetRelevantIssuers({
    holdingsSetting,
    assetTypeSetting,
  });

  const onSubmit = useCallback(
    async (formData) => {
      await sendEmail(formData);
      onClose();
    },
    [onClose, sendEmail]
  );

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "files",
  // });

  // const handleRemoveFile = (index: number) => {
  //   remove(index);
  // };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8 w-[600px]">
          <div className="flex flex-col gap-4">
            <LineBreakText text={t("contactModal.whoToContact")} />
            <Controller
              control={control}
              name="holdingsSetting"
              render={({ field: { onChange, value } }) => (
                <Radio
                  name="holdingsSetting"
                  label="Vad vill du kontakta?"
                  options={holdingsOptions}
                  selected={value}
                  onSelect={onChange}
                />
              )}
            />
            <FormSelect
              text="sm"
              fontWeight="font-normal"
              name="assetType"
              control={control}
              label={t("contactModal.fields.assetType")}
              options={assetTypeOptions}
            />
            {holdingsSetting && (
              <Controller
                control={control}
                name="externalIds"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label className="text-sm font-normal">
                      {t("contactModal.fields.pickIssuers")}
                    </label>
                    <IssuerSelect
                      issuers={issuerOptions.data}
                      selected={value}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />
            )}
            <LineBreakText text={t("contactModal.yourInfo")} />
            <Input
              label={t("contactModal.fields.name")}
              required
              type="text"
              {...register("senderName")}
            />
            <Input
              label={t("contactModal.fields.email")}
              type="email"
              required
              {...register("senderEmail")}
            />
            <Input
              label={t("contactModal.fields.subject")}
              required
              type="text"
              {...register("subject")}
            />
            <Textarea
              label={t("contactModal.fields.text")}
              className="min-h-[140px]"
              required
              {...register("text")}
            />
            {/* <Controller
              control={control}
              name="files"
              key="files"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <FileUpload
                      label={t("contactModal.label")}
                      description={t("contactModal.description")}
                      handleDrop={(acceptedFiles, rejectedFiles) => {
                        if (acceptedFiles) {
                          append(
                            acceptedFiles.map((file) => ({
                              src: file,
                            }))
                          );
                        }
                        if (rejectedFiles && rejectedFiles.length > 0) {
                          append(
                            rejectedFiles.map((file) => ({
                              src: file.file,
                              error: file.errors[0].code,
                            }))
                          );
                        }
                      }}
                      acceptedFormats={acceptedFormats}
                      maxSize={MAX_FILE_SIZE}
                    />

                    {error?.message && (
                      <p className="text-red-500">{t(error.message)}</p>
                    )}
                    {fields.map((field, i) => (
                      <div
                        key={field.id}
                        className="flex justify-between items-center p-2 rounded border"
                      >
                        <div className="flex items-center">
                          <p className="px-4 text-2xl text-secondary-100 cursor-pointer mgc_file_line" />
                          <div>
                            <p className="text-sm text-black">
                              {field.src.name}
                            </p>
                            {field.error ? (
                              <p className="text-xs font-normal text-red-500">
                                {invalidFileMsg(field.error)}{" "}
                                {convertToMB(field.src.size).toFixed(2)}MB
                              </p>
                            ) : (
                              <p className="text-xs font-normal">
                                {convertToMB(field.src.size).toFixed(2)}MB
                              </p>
                            )}
                          </div>
                        </div>
                        <button onClick={() => handleRemoveFile(i)}>
                          <span className="pr-2 text-2xl text-secondary-100 cursor-pointer mgc_close_line" />
                        </button>
                      </div>
                    ))}
                  </>
                );
              }}
            /> */}
          </div>
          <Button
            disabled={!isValid}
            type="submit"
            variant="Primary"
            size="md"
            rounded="lg"
          >
            <span>{t("contactModal.contactPerson")}</span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MailIssuersModalContent;
