import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useGetContactInfo } from "../initial/useGetContactInfo";
import { Issuer, SecurityDetailsQuery } from "./types";

const SECURITY_DETAILS_QUERY = gql`
  query GetSecurityDetails(
    $securityId: Long
    $currency: String
    $filterTags: [String]
  ) {
    security(id: $securityId) {
      id
      name
      namesAsMap
      securityCode
      isinCode
      url
      url2
      currency {
        securityCode
      }
      allocations {
        group {
          code
        }
        sector {
          namesAsMap
          name
        }
      }
      share
      latestMarketData {
        id
        date: obsDate
        price: closeView
      }
      type {
        id
        code
        namesAsMap
        name
      }
      fxRate(quoteCurrency: $currency)
      tagsAsSet
      fee: exPostSecCostCat2
      classType5 {
        value
        code
      }
      issuer {
        name
        id
      }
      issuerTmp: classType2 {
        id: code
        name: value
      }
      documents(filterTags: $filterTags) {
        fileName
        identifier
        mimeType
      }
      amountDecimalCount
    }
  }
`;

export const useGetSecurityDetails = (
  securityId: string | undefined,
  currencyCode?: string
) => {
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfoliosCurrency } = { portfoliosCurrency: "EUR" } } =
    useGetContactInfo(false, selectedContactId);
  const { loading, error, data } = useQuery<SecurityDetailsQuery>(
    SECURITY_DETAILS_QUERY,
    {
      variables: {
        securityId: securityId,
        currency: currencyCode || portfoliosCurrency,
        filterTags: ["Online"],
      },
      ...getFetchPolicyOptions(`useGetSecurityDetails.${securityId}`),
    }
  );

  return {
    loading,
    error,
    data: data && {
      ...data?.security,
      issuer:
        data?.security?.issuer ||
        ({
          id: Number(data?.security.issuerTmp?.id),
          name: data?.security.issuerTmp?.name,
        } as Issuer),
    },
  };
};
