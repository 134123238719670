import { useEffect, useRef, useState } from "react";
import { useGetSecurityMarketDataHistory } from "api/holdings/useGetSecurityMarketDataHistory";
import { ButtonRadio, Center, LineChart, LoadingIndicator } from "components";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const chartRangeOptions = [
  {
    id: "DAYS-6",
    label: "1W",
  },
  {
    id: "MONTHS-1",
    label: "1M",
  },
  {
    id: "MONTHS-3",
    label: "1Q",
  },
  {
    id: "CALYEAR-0",
    label: "YTD",
  },
  {
    id: "CALENDYEAR-0",
    label: "All",
  },
];

interface MarketHistoryDataChartProps {
  id: string | number | undefined;
}

export const MarketHistoryDataChart = ({ id }: MarketHistoryDataChartProps) => {
  const { t } = useModifiedTranslation();
  const [selectedRange, setRange] = useState(chartRangeOptions[3]);
  const { loading: securityLoading, data: securityData } =
    useGetSecurityMarketDataHistory(id, selectedRange.id);
  const { marketDataHistory = [] } = securityData || {};
  const data = marketDataHistory.map((data) => [
    new Date(data.date),
    data.price,
  ]);

  const ref = useRef<(number | Date)[][]>([]);
  useEffect(() => {
    ref.current = data;
  }, [data]);

  return (
    <div className="flex flex-col my-2 grow">
      <div className="relative grow">
        {securityLoading && (
          <div className="absolute w-full h-full">
            <LoadingIndicator center />
          </div>
        )}
        {!securityLoading && marketDataHistory.length === 0 && (
          <div className="absolute inset-0">
            <Center>{t("messages.noDataAvailable")}</Center>
          </div>
        )}
        <LineChart
          series={[
            {
              name: t("holdingsPage.price"),
              data: !securityLoading ? data : ref.current,
            },
          ]}
          detailed
          options={{
            legend: {
              show: false,
            },
          }}
        />
      </div>
      <div className="my-2.5 mx-2">
        <ButtonRadio
          value={selectedRange}
          onChange={setRange}
          options={chartRangeOptions}
        />
      </div>
    </div>
  );
};
