import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Portfolio, useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "./ContractIdProvider";

type ContextProps = {
  allPortfolioIds: number[];
  allPortfolioIdsAsString: string[];
  connectedIds: string[];
  getPortfolioById: (id: number) => Portfolio | undefined;
  getPortfolioIdsById: (id: number, withParentPorfolio?: boolean) => number[];
  getPortfolioIdsByIdAsString: (
    id: number,
    withParentPorfolio?: boolean
  ) => string[];
  getPortfolioNamesById: (id: number, withParentPorfolio?: boolean) => string[];
  portfoliosCurrency: string;
};

const PortfolioContext = createContext<ContextProps | undefined>(undefined);

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const { selectedContactId } = useGetContractIdData();
  const {
    data: { portfolios, portfoliosCurrency } = {
      portfolios: [],
      portfoliosCurrency: "EUR",
    },
  } = useGetContactInfo(false, selectedContactId);

  const getPortfolioIdsById = useCallback(
    (id: number, withParentPorfolio = true) => {
      const portfolio = portfolios.find((portfolio) => portfolio.id === id);
      if (portfolio?.portfolios.length) {
        return [
          ...(withParentPorfolio ? [portfolio.id] : []),
          ...portfolio.portfolios.map((portfolio) => portfolio.id),
        ];
      }
      if (portfolio?.id) {
        return [portfolio.id];
      }
      return [];
    },
    [portfolios]
  );
  const getPortfolioNamesById = useCallback(
    (id: number, withParentPorfolio = true): string[] => {
      const portfolio = portfolios.find((portfolio) => portfolio.id === id);
      if (portfolio?.portfolios.length) {
        return [
          ...(withParentPorfolio ? [portfolio.name] : []),
          ...portfolio.portfolios.map((portfolio) => portfolio.name),
        ];
      }
      if (portfolio?.name) {
        return [portfolio.name];
      }
      return [];
    },
    [portfolios]
  );

  const getPortfolioIdsByIdAsString = useCallback(
    (id: number, withParentPorfolio = true) => {
      return getPortfolioIdsById(id, withParentPorfolio).map((id) =>
        id.toString()
      );
    },
    [getPortfolioIdsById]
  );

  const getPortfolioById = useCallback(
    (id: number) => {
      return portfolios.find((portfolio) => portfolio.id === id);
    },
    [portfolios]
  );

  // Return portflio ids from all sub portfolios of portfolios if portfolio don't have any sub portfolios and is not a sub portfolio itself return the portfolio id
  const allPortfolioIds = useMemo(() => {
    return (portfolios as Portfolio[]).reduce((acc, portfolio) => {
      const { portfolios: subPortfolios } = portfolio;
      if (subPortfolios.length) {
        return [...acc, ...subPortfolios.map((portfolio) => portfolio.id)];
      }
      if (
        !portfolios.some((otherPortfolio) => {
          const { portfolios } = otherPortfolio;
          return portfolios.some(({ id: subPortfolioId }) => {
            return subPortfolioId === portfolio.id;
          });
        })
      ) {
        return [...acc, portfolio.id];
      }
      return acc;
    }, [] as number[]);
  }, [portfolios]);

  const connectedIds = useMemo(() => {
    return portfolios.map(({ id }) => id.toString());
  }, [portfolios]);

  return (
    <PortfolioContext.Provider
      value={{
        getPortfolioIdsById,
        getPortfolioIdsByIdAsString,
        getPortfolioNamesById,
        getPortfolioById,
        allPortfolioIdsAsString: allPortfolioIds.map((id) => id.toString()),
        allPortfolioIds,
        connectedIds,
        portfoliosCurrency,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const useGetPortfolioData = () => {
  const state = useContext(PortfolioContext);
  if (!state) throw new Error("detail data not found");

  return state;
};
