import { ReactNode } from "react";
import classNames from "classnames";

interface GainLoseColoringProps {
  children: ReactNode;
  value: number;
  theme?: "light" | "dark";
}

export const GainLoseColoring = ({
  children,
  value,
  theme = "light",
}: GainLoseColoringProps) => (
  <div
    className={classNames({
      "text-other-red": value < 0,
      "text-other-green": value > 0,
      "text-primary-100": theme === "light" && value === 0,
      "text-white-100": theme === "dark" && value === 0,
    })}
  >
    {children}
  </div>
);
