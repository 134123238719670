import { createContext, ReactNode, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { useSessionStorage } from "usehooks-ts";

const SessionContext = createContext<string | undefined>(undefined);

type SessionAuth = {
  email: string | undefined;
  password: string | undefined;
};
export const SessionProvider = ({
  url,
  auth,
  children,
}: {
  url: string | undefined;
  auth: SessionAuth;
  children: ReactNode;
}) => {
  const getToken = async (
    url: string | undefined,
    auth: SessionAuth
  ): Promise<string> => {
    if (!url) throw new Error("No API URL defined");
    const mutation = `
          mutation Mutation($email: String!, $password: String!) {
            authenticateUserWithPassword(email: $email, password: $password) {
              ... on UserAuthenticationWithPasswordSuccess {
                sessionToken
              }
            }
          }
        `;

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios
      .post(
        `${url}/graphql`,
        {
          query: mutation,
          variables: auth,
        },
        options
      )
      .catch((error) => {
        throw new Error(error);
      });

    if (!response.data.data.authenticateUserWithPassword?.sessionToken) {
      throw new Error("Invalid credentials");
    }
    return response.data.data.authenticateUserWithPassword.sessionToken;
  };

  const [storedToken, setStoredToken] = useSessionStorage<string | undefined>(
    "apiCredentials",
    undefined
  );

  useQuery<string, Error>({
    queryKey: ["apiCredentials"],
    queryFn: () => getToken(url, auth),
    enabled: !storedToken,
    retry: 2,
    onSuccess: (data) => {
      setStoredToken(data);
    },
    onError: () => {
      setStoredToken(undefined);
    },
  });

  return (
    <SessionContext.Provider value={storedToken}>
      {children}
    </SessionContext.Provider>
  );
};

export const useGetSessionToken = () => {
  const sessionToken = useContext(SessionContext);
  return sessionToken;
};
