import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useNavigateToPortfolioTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (urlPrefix: string) => {
      const pathSegments = location.pathname.split("/").filter(Boolean);
      const prefixSegments = urlPrefix.split("/").filter(Boolean);
      if (
        pathSegments[0] === "portfolio" &&
        prefixSegments[0] === "portfolio"
      ) {
        pathSegments.splice(1, 1, prefixSegments[1]);
        const relevantPath = pathSegments.join("/");
        navigate(`/${relevantPath}`);
        return;
      }
      if (prefixSegments[0] === "portfolio") {
        const relevantPath = [...prefixSegments, ...pathSegments].join("/");
        navigate(`/${relevantPath}`);
        return;
      }

      pathSegments.splice(0, 2);
      const relevantPath = pathSegments.join("/");
      navigate(`/${relevantPath}`);
    },
    [navigate, location]
  );
};
