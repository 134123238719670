import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import {
  getSectorFromAllocations,
  getSectorIdFromAllocations,
} from "utils/securities";
import { SECURITIES_FIELDS } from "./fragments";
import { SecuritiesQuery, Security } from "./types";

const ALL_SECURITIES_QUERY = gql`
  ${SECURITIES_FIELDS}
  query AllSecurities {
    fundSecurities: securities(status: "ACTIVE", securityType: "FUND") {
      ...SecurityFields
      __typename
    }
    privateEquitySecurities: securities(status: "ACTIVE", securityType: "PE") {
      ...SecurityFields
      __typename
    }
  }
`;

export const useGetSecurities = () => {
  const { i18n } = useModifiedTranslation();
  const { loading, error, data } = useQuery<SecuritiesQuery>(
    ALL_SECURITIES_QUERY,
    {
      ...getFetchPolicyOptions(`useGetSecurities`),
    }
  );

  const result =
    data?.privateEquitySecurities &&
    data?.fundSecurities &&
    [...data.fundSecurities, ...data.privateEquitySecurities]
      .map(
        (security): Omit<Security, "issuerTmp"> => ({
          ...security,
          sector:
            getSectorFromAllocations(security.allocations, i18n.language) ?? "",
          sectorCode: getSectorIdFromAllocations(security.allocations) ?? "",
          sustainability: security.classType5?.code ?? "",
          issuer: security.issuer ?? security.issuerTmp,
        })
      )
      .sort((a, b) => a.name.localeCompare(b.name));

  return {
    loading,
    error,
    data: result,
  };
};
