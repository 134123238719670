import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from "@apollo/client";
import { QueryData } from "api/types";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { toast } from "react-toastify";
import { Card } from "../Card/Card";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export type Refetch<T> = (
  variables?: Partial<OperationVariables> | undefined
) => Promise<ApolloQueryResult<T>>;

export interface QueryLoadingWrapperProps<T>
  extends Omit<QueryData<T>, "error"> {
  error: ApolloError | Error | undefined | null;
  refetch?: Refetch<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SuccessComponent: (props: any) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successComponentProps?: any;
}
const QUERY_ERROR_TOAST_ID = "QUERY_ERROR_TOAST_ID";

export const QueryLoadingWrapper = <TData,>({
  error,
  loading,
  data,
  refetch,
  SuccessComponent,
  successComponentProps,
}: QueryLoadingWrapperProps<TData>) => {
  const { t } = useModifiedTranslation();
  if (error) {
    toast.error(t("messages.queryErrorWarning"), {
      toastId: QUERY_ERROR_TOAST_ID,
      autoClose: 5000,
    });
  }

  if (data) {
    return (
      <SuccessComponent
        data={data}
        refetch={refetch}
        {...successComponentProps}
      />
    );
  }
  // when offline and do not have cached data returns data === undefined, no error and not loading
  if (!loading || (error && !data)) {
    return (
      <Card bg="transparent">
        <ErrorMessage header={t("messages.noCachedData")}>
          {t("messages.noCachedDataInfo")}
        </ErrorMessage>
      </Card>
    );

    //<div className="min-h-[400px]">No cached data</div>;
  }
  return <LoadingIndicator center />;
};
