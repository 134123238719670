import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
  isAllowed: boolean;
  redirectPath?: string;
}

export const ProtectedRoute = ({
  children,
  isAllowed = false,
  redirectPath = "/",
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};
