import MailIssuersModalContent from "components/ContactModal/EmailIssuersModalContent";
import { useModal } from "components/Modal/useModal";
import { t } from "i18next";

const ContactIssuers = () => {
  const {
    Modal,
    onOpen: onMailIssuersModalOpen,
    modalProps,
    contentProps,
  } = useModal();

  return (
    <>
      <button
        className="flex gap-2.5 justify-between lg:justify-start items-center py-2 px-3 bg-ghost-white-60 rounded-lg border border-primary-10 h-[56px]"
        onClick={() => onMailIssuersModalOpen()}
      >
        <span className="text-2xl mgc_chat_2_line" />
        <span className="text-body-small">{t("contactModal.buttonLabel")}</span>
      </button>
      <Modal {...modalProps} header={t("contactModal.header")}>
        <MailIssuersModalContent {...contentProps} />
      </Modal>
    </>
  );
};

export default ContactIssuers;
