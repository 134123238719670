import { useQuery } from "@tanstack/react-query";
import { portalAPI } from "api/helper";
import { t } from "i18next";
import { useGetSessionToken } from "providers/SessionProvider";

interface QueryVariables {
  where: { externalId: string | undefined };
}

interface SecurityPartnerDetailsQuery {
  data: {
    fund: {
      name: string;
    };
  };
}

const query = `query GetIsSecurityPartnerTradeble($where: FundWhereUniqueInput!) {
    fund(where: $where) {
      name
    }
  }
  `;

const getIsSecurityPartnerTradeble = async (
  externalId: string | undefined,
  token: string | undefined
): Promise<SecurityPartnerDetailsQuery> => {
  const variables = {
    where: {
      externalId: externalId,
    },
  };

  try {
    const response = await portalAPI<
      SecurityPartnerDetailsQuery,
      QueryVariables
    >({
      path: "graphql",
      query,
      variables,
      token: token,
    });

    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(t("messages.failedToFetch", { error: error.message }));
    } else {
      throw new Error(t("messages.error"));
    }
  }
};

export const useGetIsSecurityPartnerTradeble = (id: string | undefined) => {
  const token = useGetSessionToken();
  const { isInitialLoading, error, data } = useQuery<
    SecurityPartnerDetailsQuery,
    Error
  >(
    ["getIsSecurityPartnerTradeble", id],
    () => getIsSecurityPartnerTradeble(id, token),
    {
      enabled: !!token,
    }
  );

  const isPartnerTradebable = !!data?.data?.fund;

  return {
    loading: isInitialLoading,
    error,
    isPartnerTradebable,
  };
};
