import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { initials } from "utils/initials";

export type SelectedContact = {
  id: string | number;
  contactId: string | number;
  userName: string;
  initials: string;
};

type ContextProps = {
  selectedContactId: string | number;
  selectedContact: SelectedContact;
  setSelectedContactId: Dispatch<SetStateAction<string | number>>;
  setSelectedContact: Dispatch<SetStateAction<SelectedContact>>;
};

const ContractIdContext = createContext<ContextProps | undefined>(undefined);

export const DetailProvider = ({ children }: { children: ReactNode }) => {
  const [selectedContactId, setSelectedContactId] = useState<string | number>(
    ""
  );
  const [selectedContact, setSelectedContact] = useState<SelectedContact>(
    {} as SelectedContact
  );
  const { data } = useGetContactInfo();
  useEffect(() => {
    const hasRespresentees =
      data?.representees && data?.representees?.length > 0;
    const contactStorage = window.localStorage.getItem("contact");
    if (contactStorage) {
      const contact = JSON.parse(contactStorage);
      const hasAccess =
        data?.representees?.some((rep) => rep.id === contact.id) ?? false;
      if (hasAccess) {
        setSelectedContactId(contact.id);
        setSelectedContact(contact);
        return;
      }
    }
    if (
      hasRespresentees &&
      data?.representees &&
      data?.representees?.length > 0
    ) {
      const contact = data.representees
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))[0];
      setSelectedContact({
        id: contact.id,
        contactId: contact.contactId,
        userName: contact.name,
        initials: initials(contact.name),
      });
      setSelectedContactId(contact.id);
      return;
    }

    setSelectedContact({
      id: data?.contactId ?? "",
      contactId: data?.contactId ?? "",
      userName: data?.name ?? "",
      initials: initials(data?.name) ?? "",
    });
    setSelectedContactId(data?.contactId ?? "");
  }, [data?.contactId, data?.name, data?.representees]);

  return (
    <ContractIdContext.Provider
      value={{
        selectedContactId,
        selectedContact,
        setSelectedContactId,
        setSelectedContact,
      }}
    >
      {children}
    </ContractIdContext.Provider>
  );
};

export const useGetContractIdData = () => {
  const state = useContext(ContractIdContext);
  if (!state) throw new Error("detail data not found");

  return state;
};
