import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { Start } from "pages/userWithLinkedContact";
import { APILanguageProvider } from "providers/APILanguageProvider/APILanguageProvider";
import { DetailProvider } from "providers/ContractIdProvider";
import { DateRangeProvider } from "providers/DateRangeProvider";
import { InitialLanguageProvider } from "providers/InitialLanguageProvider";
import { KeycloakProvider } from "providers/KeycloakProvider";
import { NavTabProvider } from "providers/NavTabProvider";
import { PersistedApolloProvider } from "providers/PersistedApolloProvider";
import { PortfolioProvider } from "providers/PortfolioProvider";
import { PostNotificationProvider } from "providers/PostNotificationProvider";
import { ReportSettingsProvider } from "providers/ReportSettingsProvider";
import { ServiceWorkerRegistrationProvider } from "providers/ServiceWorkerRegistrationProvider";
import { SessionProvider } from "providers/SessionProvider";
import "react-toastify/dist/ReactToastify.css";
import "styles/fonts.css";
import "./assets/MingCute/MingCute.css";
import { Toast } from "./components";

function App() {
  const queryClient = new QueryClient();
  return (
    <InitialLanguageProvider>
      <ServiceWorkerRegistrationProvider>
        <ErrorBoundary>
          <KeycloakProvider>
            <DateRangeProvider>
              <QueryClientProvider client={queryClient}>
                <SessionProvider
                  url={process.env.REACT_APP_API_URL_LEV}
                  auth={{
                    email: process.env.REACT_APP_API_LOGIN_EMAIL,
                    password: process.env.REACT_APP_API_LOGIN_PASSWORD,
                  }}
                >
                  <PersistedApolloProvider>
                    <DetailProvider>
                      <ReportSettingsProvider>
                        <NavTabProvider>
                          <PortfolioProvider>
                            <APILanguageProvider>
                              <PostNotificationProvider>
                                <ServiceWorkerRegistrationProvider>
                                  <Start />
                                </ServiceWorkerRegistrationProvider>
                              </PostNotificationProvider>
                            </APILanguageProvider>
                          </PortfolioProvider>
                        </NavTabProvider>
                      </ReportSettingsProvider>
                    </DetailProvider>
                  </PersistedApolloProvider>
                </SessionProvider>
              </QueryClientProvider>
            </DateRangeProvider>
          </KeycloakProvider>
          <Toast />
        </ErrorBoundary>
      </ServiceWorkerRegistrationProvider>
    </InitialLanguageProvider>
  );
}

export default App;
