import { useCallback } from "react";
import { StringMap, TOptions } from "i18next";
import { useTranslation } from "react-i18next";

export type IT = (
  key: string,
  options?: TOptions<StringMap> | undefined
) => string;
export const useModifiedTranslation = () => {
  const { i18n, t } = useTranslation();

  // for all languages currencies are displayed as ISO code at the end of value - business decision
  const modifiedT = useCallback(
    (key: string, options?: TOptions<StringMap>) => {
      if (key === "number") {
        return `${t("number", {
          ...options,
          maximumFractionDigits: 0,
        })}`;
      }

      if (key === "numberWithPercent") {
        return `${
          t("number", {
            ...options,
            maximumFractionDigits: 2,
          }) + "%"
        }`;
      }

      if (key === "numberWithCurrency") {
        if (options?.currency) {
          const { currency, ...optionsWOCurrency } = options;
          return `${t("number", {
            ...optionsWOCurrency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, //TODO: HITTA HIT
          })}\xa0${currency}`;
        }
      }

      if (key === "numberWithSign") {
        if (options?.sign) {
          const { sign, ...optionsWOCurrency } = options;
          return `${t("numberRounded", optionsWOCurrency)}${sign}`;
        }
      }

      if (key === "numberWithCurrencyRounded") {
        if (options?.currency) {
          const { currency, maxFractionDigits, ...optionsWOCurrency } = options;
          return `${t("numberRounded", {
            ...optionsWOCurrency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}\xa0${currency}`;
        }
      }

      // if (key === "numberWithCustomDecimals") {
      //   if (options) {
      //     return `${t("number", {
      //       ...options,
      //       minimumFractionDigits: 0,
      //     })}`;
      //   }
      // }

      if (key === "numberWithDecimal") {
        if (options?.sign) {
          const { sign, ...optionsWOCurrency } = options;
          return `${t("numberWithDecimal", optionsWOCurrency)}\xa0${sign}`;
        }
      }

      if (key === "transactionStatus") {
        if (options?.status) {
          return options.status === "OK"
            ? t("bookkeepingPage.accepted")
            : options.status === "DEL"
            ? t("bookkeepingPage.rejected")
            : "N/A";
        }
      }

      if (key === "bookkeepingStatus") {
        if (options?.status) {
          return options.status === "READY"
            ? t("bookkeepingPage.notReported")
            : options.status === "REPORTED"
            ? t("bookkeepingPage.reported")
            : "N/A";
        }
      }

      return t(key, options);
    },
    [t]
  );

  return { i18n, t: modifiedT };
};
