import { ComponentProps, FC, Fragment } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { PagesCarouselProps } from "../PagesCarousel/PagesCarousel";

// type NavTabType = typeof Tab & {
//   CarouselPanels: (props: PagesCarouselProps) => JSX.Element;
//   NavTab: ForwardRefExoticComponent<
//     ComponentProps<FC> &
//       RefAttributes<HTMLElement> & {
//         disabled?: boolean;
//         onClick?: () => void;
//       }
//   >;
// };

const NavTab = (
  props: ComponentProps<FC> & {
    disabled?: boolean;
    onClick?: () => void;
  }
) => (
  <Tab
    className={({ selected }) =>
      classNames(
        " flex items-center gap-3 pl-4 py-3 font-normal text-primary-80 text-body lg:text-body-small whitespace-nowrap w-full rounded-lg hover:bg-hover hover:text-white-100 transition-colors  disabled:text-primary-20 disabled:hover:!text-primary-20 disabled:hover:!bg-transparent",
        {
          "border-b border-secondary-80  text-secondary-8 bg-secondary-100 !text-white-100 fill-white-100":
            selected,
          "border-b border-transparent text-primary-60 font-normal ": !selected,
        }
      )
    }
    {...props}
  />
);

NavTab.displayName = "NavTab";
NavTab.NavTab = NavTab;
NavTab.NavTab.displayName = "NavTab.NavTab";

NavTab.Group = Tab.Group;

type NavTabListType = typeof Tab.List;
const NavTabList: NavTabListType = (props) => (
  <nav className="lg:block overflow-y-scroll overscroll-contain sticky left-0 z-10 py-5 px-4 w-screen bg-white-100 border-b border-gray-200 shadow-md top-[96px] h-[calc(100dvh-96px)] scroll-hidden lg:w-[200px]">
    <Tab.List
      className="flex sticky flex-col flex-nowrap gap-1 items-start scroll-hidden"
      {...props}
    />
  </nav>
);
NavTabList.displayName = "NavTabList";
NavTab.List = NavTabList;

const NavTabPanels = (props: PagesCarouselProps) => (
  <>{props.children}</>
  // <PagesCarousel {...props}>{props.children}</PagesCarousel>
);
NavTab.CarouselPanels = NavTabPanels;

NavTab.Panels = Tab.Panels;

type NavTabPanelType = typeof Tab.Panel;
const NavTabPanel: NavTabPanelType = (props) => (
  <>
    <Fragment {...props} />
  </>
);
NavTabPanel.displayName = "NavTabPanel";
NavTab.Panel = NavTabPanel;

export { NavTab };
