import { createContext, ReactNode, useContext, useReducer } from "react";
import { useGetContactPages } from "api/initial/useGetContactPages";
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll";

type ContextProps = {
  isOpen: boolean;
  dispatch: React.Dispatch<NavTabAction>;
  data: string[];
};

type NavTabAction = { type: ACTIONS };

export enum ACTIONS {
  TOGGLE,
  CLOSE,
}

const NavTabContext = createContext<ContextProps | undefined>(undefined);

const reducer = (state: boolean, action: NavTabAction) => {
  switch (action.type) {
    case ACTIONS.TOGGLE:
      return !state;
    case ACTIONS.CLOSE:
      return false;
    default:
      return state;
  }
};

export const NavTabProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useGetContactPages();

  const pages = data ?? [];
  const [isOpen, dispatch] = useReducer(reducer, false);
  useDisableBodyScroll(isOpen);

  return (
    <NavTabContext.Provider value={{ isOpen, dispatch, data: pages }}>
      {children}
    </NavTabContext.Provider>
  );
};

export const useNavTabProvider = () => {
  const state = useContext(NavTabContext);
  if (!state) throw new Error("NavTab context not found");

  return state;
};
