import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "components";
import { useKeycloak } from "providers/KeycloakProvider";
import { useForm, useFieldArray } from "react-hook-form";
import { tradeOrderSignaturesSchema } from "./TradeOrderSignaturesSchema";

export interface Signatories {
  name: string;
  email: string;
}

interface FormValues {
  signatories: Signatories[];
}

interface TradeOrderSignaturesFormProps {
  setIsValid: (isValid: boolean) => void;
  setSignatories: (signatories: Signatories[]) => void;
}

const TradeOrderSignaturesForm = ({
  setSignatories,
  setIsValid,
}: TradeOrderSignaturesFormProps) => {
  const { userProfile } = useKeycloak();

  const {
    control,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: zodResolver(tradeOrderSignaturesSchema),
    defaultValues: {
      signatories: [
        {
          name: userProfile?.firstName + " " + userProfile?.lastName,
          email: userProfile?.email,
        },
      ],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "signatories",
  });

  const watchedSignatories = watch("signatories");

  useEffect(() => {
    setIsValid(isValid);
    if (isValid) {
      setSignatories(watchedSignatories);
    }
  }, [watchedSignatories, isValid, setSignatories, setIsValid]);

  return (
    <form className="flex flex-col gap-2 w-full">
      <p>Signerare</p>
      {fields.map((field, index) => (
        <div
          key={field.id}
          className="flex relative flex-col gap-2 p-4 rounded border "
        >
          <button
            className="absolute top-0 right-0 gap-1 justify-end items-center w-10 h-10 "
            type="button"
            onClick={() => remove(index)}
          >
            <span className="mgc_close_line"></span>
          </button>
          <Input
            label="Name"
            {...register(`signatories.${index}.name`)}
            error={errors?.signatories?.[index]?.name?.message}
          />
          <Input
            label="Email"
            {...register(`signatories.${index}.email`)}
            error={errors?.signatories?.[index]?.email?.message}
          />
        </div>
      ))}
      <div>
        <Button
          LeftIcon={<span className="mgc_add_line" />}
          variant="Secondary"
          type="button"
          onClick={() => append({ name: "", email: "" })}
        >
          Lägg till ytterligare signerare
        </Button>
      </div>
    </form>
  );
};

export default TradeOrderSignaturesForm;
