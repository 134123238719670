import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import classNames from "classnames";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetPortfolioData } from "providers/PortfolioProvider";
import { useLocation, useNavigate } from "react-router-dom";

interface LocalNavigationProps {
  back?: boolean;
  extra?: string;
  backTo?: string;
}

export const LocalNavigation = ({
  back,
  extra,
  backTo,
}: LocalNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useModifiedTranslation();
  const { getPortfolioById } = useGetPortfolioData();
  const paths = location.pathname.split("/");
  const portfolioId = paths[2];
  let mainRoute = paths[1];
  if (paths.includes("portfolio")) {
    paths.splice(0, 3);
    mainRoute = paths[0];
  }
  const portfolio = getPortfolioById(Number(portfolioId));

  if (back) {
    return (
      <div
        onClick={() => navigate(-1)}
        className="group flex static lg:absolute top-8 gap-1 items-center text-body-small text-primary-60 hover:text-primary-80 transition-colors cursor-pointer"
      >
        <ChevronLeft className="transition-colors group-hover:fill-primary-80 fill-primary-60" />
        <span>{t("navTab.localNavigation.back")}</span>
      </div>
    );
  }

  if (backTo) {
    return (
      <div
        onClick={() => navigate(backTo)}
        className="group flex static lg:absolute top-8 gap-1 items-center text-body-small text-primary-60 hover:text-primary-80 transition-colors cursor-pointer"
      >
        <ChevronLeft className="transition-colors group-hover:fill-primary-80 fill-primary-60" />
        <span>{t("navTab.localNavigation.back")}</span>
      </div>
    );
  }

  if (!mainRoute) {
    return null;
  }

  const mainRouteTitle = t(`navTab.tabs.${mainRoute}`);
  const navigations = [
    mainRouteTitle,
    ...(portfolio ? [portfolio.name] : []),
    ...(extra ? [extra] : []),
  ];
  return (
    <div className="hidden md:flex lg:absolute top-8 gap-3 text-body-small text-primary-50">
      {navigations.map((navigation, index) => (
        <div
          onClick={() => {
            if (index === 0) {
              navigate(`/${mainRoute}`);
              return;
            }
            if (index !== navigations.length - 1) {
              const paths = location.pathname.split("/");
              paths.pop();
              navigate(paths.join("/"));
              return;
            }
          }}
          key={index}
          className={classNames("flex gap-2 items-center cursor-pointer", {
            "text-primary-30 cursor-default": index === navigations.length - 1,
          })}
        >
          <span>{navigation}</span>
          {index !== navigations.length - 1 && <ChevronRight />}
        </div>
      ))}
    </div>
  );
};
