import { ReactNode } from "react";

interface ITitleProps {
  text: ReactNode;
}

export const Title = (props: ITitleProps) => {
  const { text } = props;

  return (
    <span className="pt-4 md:pt-0 text-headline-1 font-semibold text-primary-100">
      {text}
    </span>
  );
};
