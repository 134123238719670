import { gql } from "@apollo/client";

export const ALLOCATION_BY_SECURITY_TYPE_FIELDS = gql`
  fragment AllocationBySecurityTypeFields on GrouppedAnalyticsDTO {
    allocationByType: grouppedAnalytics {
      code
      name
      figures: firstAnalysis {
        marketValue
        tradeAmountOrig
        fxRate
        amount
        shareOfTotal
        twr
      }
      allocationsBySector: grouppedAnalytics {
        code
        name
        security {
          class2Code
          class2Name
          id
          isinCode
          countryCode
          currencyCode
          tagsAsList
        }
        figures: firstAnalysis {
          marketValue
          tradeAmountOrig
          fxRate
          amount
          shareOfTotal
          twr
        }
        allocationsBySecurity: grouppedAnalytics {
          code
          name
          security: security {
            class2Code
            class2Name
            id
            isinCode
            countryCode
            currencyCode
            tagsAsList
          }
          figures: firstAnalysis {
            marketValue
            tradeAmountOrig
            fxRate
            amount
            shareOfTotal
            twr
          }
        }
      }
    }
  }
`;

//TODO: Kolla över : origTradeAmount kanske uppdateras i nyare version av FA
export const PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS = gql`
  fragment PortfolioReportHoldingDetailsFields on PortfolioReport {
    holdingPositions: portfolioReportItems {
      portfolioId
      security {
        id
      }
      amount
      accruedInterest
      tradeAmountOrig: origTradeAmount
      marketValue
      valueChangeAbsolute
      valueChangeRelative
      marketFxRate
    }
  }
`;

export const ALL_PORTFOLIOS_REPORT_HOLDINGS_DETAILS_FIELDS = gql`
  fragment AllPortfoliosReportHoldingDetailsFields on PortfolioReport {
    holdingPositions: portfolioReportItems {
      portfolioId: portfolio {
        id
        contact {
          id
        }
      }
      security {
        id
      }
      amount
      tradeAmountOrig: origTradeAmount
      accruedInterest
      marketValue
      valueChangeAbsolute
      valueChangeRelative
      marketFxRate
      shareOfPortfolio
    }
  }
`;

export const SECURITY_DATA_FRAGMENT = gql`
  fragment SecurityData on GrouppedAnalyticsDTO {
    securities: grouppedAnalytics {
      code
      name
      security {
        id
        isinCode
        countryCode
        currencyCode
        tagsAsList
        securityTypeCode
      }
      firstAnalysis {
        amount
        accruedInterest
        tradeAmountOrig
        marketValue
        marketFxRate
        unrealizedProfits
        shareOfTotal
      }
    }
  }
`;

export const SECURITY_TYPE_WITH_SECURITIES_FRAGMENT = gql`
  fragment SecurityTypeWithSecuritiesData on GrouppedAnalyticsDTO {
    securityTypes: grouppedAnalytics {
      code
      name
      firstAnalysis {
        marketValue
        tradeAmount
        shareOfTotal
      }
      ...SecurityData
    }
  }
`;
