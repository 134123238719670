import { Suspense } from "react";
import {
  LoadingIndicator,
  UserMenu,
  NavTabMobileWrapper,
  PortfolioSelect,
} from "components";
import { Line } from "components/LimitsBar/components/Line";
import { LogoutButton } from "components/LogoutButton/LogoutButton";
import {
  TOTAL_INVESTMENTS_OPTION_ID,
  useGetPortfolioOptions,
} from "hooks/useGetPortfolioOptions";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { PageLayout } from "layouts/PageLayout/PageLayout";
import { PortfolioOption } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/PortfolioNavigationHeader";
import { useNavigateToPortfolioTab } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useRedirectIfOnlyOnePortfolio";
import { ACTIONS, useNavTabProvider } from "providers/NavTabProvider";
import { Outlet, useParams } from "react-router-dom";
import { NavTab } from "./NavTab/NavTab";
import { NavTabPath } from "./NavTab/types";
import { useNavTab } from "./NavTab/useNavTab";

interface NavTabTemplateLayoutProps {
  routes: NavTabPath[];
}

export const NavTabLayoutMobile = ({ routes }: NavTabTemplateLayoutProps) => {
  const isMobile = !useMatchesBreakpoint("lg");
  const { dispatch } = useNavTabProvider();
  const portfolioOptions = useGetPortfolioOptions();
  // const { parentPortfolioOptions, portfolioOptions } = useGetPortfolioOptions();
  const { portfolioId: portfolioIdUrl } = useParams();
  const portfolioId = portfolioIdUrl
    ? parseInt(portfolioIdUrl, 10)
    : TOTAL_INVESTMENTS_OPTION_ID;
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();
  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
    if (isMobile) {
      dispatch({
        type: ACTIONS.CLOSE,
      });
    }
  };

  const { handleMenuItemClick, groupProps } = useNavTab({
    navTabPaths: routes,
  });

  //TODO: Portfolio select

  return (
    <div className="flex absolute flex-row flex-1 items-stretch">
      <NavTabMobileWrapper>
        <NavTab.Group {...groupProps}>
          <NavTab.List>
            <UserMenu />
            <PortfolioSelect
              portfolioOptions={portfolioOptions}
              portfolioId={portfolioId}
              onChange={onPortfolioChange}
            />

            {routes.map((route, index) => (
              <NavTab.NavTab
                disabled={route.disabled}
                key={`NavTab_${index}`}
                onClick={() => handleMenuItemClick(index)}
              >
                <div className="flex gap-3 items-center">
                  {route.icon} <span>{route.tabLabel}</span>
                  {route.notification && !route.disabled && (
                    <div className="w-2 bg-secondary-100 rounded-full aspect-square ml-[-8px] mt-[-10px]" />
                  )}
                </div>
              </NavTab.NavTab>
            ))}
            <div className="relative w-full">
              <Line height={2} fontSize="xsmall" minValue={0} maxValue={1} />
            </div>
            <LogoutButton />
          </NavTab.List>
        </NavTab.Group>
      </NavTabMobileWrapper>
      <PageLayout>
        <Suspense fallback={<LoadingIndicator center />}>
          <Outlet />
        </Suspense>
      </PageLayout>
    </div>
  );
};
