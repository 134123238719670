import { ComponentPropsWithoutRef, ReactNode } from "react";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";

export type Variant =
  | "Primary"
  | "Dark"
  | "Secondary"
  | "Red"
  | "Green"
  | "Light"
  | "Delete"
  | "Transparent"
  | "GhostWhite"
  | "Outlined";
type Size = "md" | "xs" | "xxs";

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: Variant;
  isFullWidth?: boolean;
  isLoading?: boolean;
  LeftIcon?: ReactNode;
  size?: Size;
  rounded?: string;
}

export const Button = ({
  type = "button",
  children,
  variant = "Primary",
  isFullWidth = false,
  isLoading = false,
  LeftIcon,
  size = "md",
  disabled,
  rounded,
  ...props
}: ButtonProps) => (
  <button
    {...props}
    disabled={disabled || isLoading}
    type={type}
    className={classNames(
      "box-border rounded inline-flex transition-colors items-center justify-center whitespace-nowrap align-middle cursor-pointer",
      {
        "bg-secondary-100 hover:bg-hover border-1 border-transparent text-white-100 fill-white disabled:hover:bg-secondary-100":
          variant === "Primary",
        "bg-red-200 hover:bg-red-300 text-red-800 fill-white disabled:hover:bg-red-200":
          variant === "Red",
        "bg-green-200 hover:bg-green-300 text-green-800 fill-white disabled:hover:bg-green-200":
          variant === "Green",
        "bg-white-100 border-1 border-secondary-80 hover:bg-secondary-20 text-secondary-80 fill-secondary-80 disabled:hover:bg-white-100":
          variant === "Secondary",
        "border-2 border-primary-600 hover:bg-primary-100 text-primary-600 fill-primary-600 leading-tight":
          variant === "Outlined",
        "bg-gray-700 hover:bg-gray-800 text-white-100 fill-white disabled:hover:bg-gray-700":
          variant === "Dark",
        "bg-secondary-20 hover:bg-secondary-40 text-secondary-90 fill-white disabled:hover:bg-secondary-30":
          variant === "Light",
        "bg-white-100border-2 border-red-600 hover:bg-red-100 text-red-600 fill-red-600 leading-tight":
          variant === "Delete",
        "text-gray-300": variant === "Transparent" && disabled,
        "bg-ghost-white-60 border-secondary-10 border hover:bg-ghost-white-100":
          variant === "GhostWhite",
        "w-full": isFullWidth,
        "text-body-small font-medium py-3 px-5": size === "md",
        "text-body-xsmall font-normal py-2 px-3": size === "xs",
        "text-body-xsmall font-normal py-1 px-2": size === "xxs",
        "opacity-50 cursor-not-allowed": disabled,
        "cursor-not-allowed": isLoading,
        [`!rounded-${rounded}`]: rounded,
      }
    )}
  >
    {(LeftIcon || isLoading) && (
      <span
        className={classNames(
          "inline-flex items-center self-center w-4 h-4 shrink-0",
          {
            "mr-2": children !== undefined,
          }
        )}
      >
        {isLoading ? (
          <Spinner
            className={classNames(
              "text-secondary-40 animate-spin fill-white",
              {
                "text-secondary-20 fill-secondary-80": variant === "Secondary",
              },
              {
                "text-secondary-20 fill-white-100": variant === "Primary",
              }
            )}
          />
        ) : (
          LeftIcon
        )}
      </span>
    )}
    {children}
  </button>
);
