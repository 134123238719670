import { ReactNode } from "react";
import { useGetHasAcceptedTOC } from "api/termsOfCondition/useGetHasAcceptedTOC";
import { Card } from "components/Card/Card";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { Navigate } from "react-router-dom";

interface TermsOfConditionGuardProps {
  children: ReactNode;
  authorized?: boolean;
}

export const TermsOfConditionGuard = ({
  children,
  authorized,
}: TermsOfConditionGuardProps) => {
  const { data, error, loading } = useGetHasAcceptedTOC();
  const { t } = useModifiedTranslation();

  if (loading)
    return (
      <div className="absolute w-screen h-screen">
        <LoadingIndicator center />
      </div>
    );

  if (!data) {
    return <Navigate to={"/terms-of-condition"} replace />;
  }
  if (error) {
    return (
      <Card bg="transparent">
        <ErrorMessage header={t("messages.noCachedData")}>
          {t("messages.noCachedDataInfo")}
        </ErrorMessage>
      </Card>
    );
  }

  return <>{children}</>;
};
