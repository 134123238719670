import { useMutateHasAcceptedTOC } from "api/termsOfCondition/useMutateHasAcceptedTOC";
import { Button } from "components";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { keycloakService } from "services/keycloakService";

export const TermsOfConditionView = () => {
  const navigate = useNavigate();
  const { updateHasAccepted } = useMutateHasAcceptedTOC({
    onSuccess: () => navigate("/"),
  });

  const logout = () => {
    window.localStorage.removeItem("contact");
    keycloakService.onAuthLogout();
  };

  return (
    <div className="flex absolute justify-center items-center w-full h-screen">
      <div className="overflow-y-scroll py-6 px-10 bg-white-100 rounded-lg shadow-xl scroll-hidden max-h-[90%] w-[500px]">
        <div>
          <img className="mx-auto w-20" src="adecla.png" alt="Adecla logo" />
          <div className="flex flex-col items-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-primary-100">
              Finansportalen
            </h1>
            <h1 className="text-lg md:text-xl ">
              {t("termsOfCondition.header")}
            </h1>
          </div>
          <p>
            {t("termsOfCondition.text")}
            <a
              className="underline"
              target="_blank"
              href="https://www.adecla.com/"
              rel="noreferrer"
            >
              {t("termsOfCondition.hyperlinkAfterText")}
            </a>
          </p>
          <br />
          <p className="text-secondary-100">
            Version: {process.env.REACT_APP_TERMS_USE_VERSION}
          </p>
          <p className="text-secondary-100">Last updated: Feb 20, 2024</p>
          <br />
          <p className="text-primary-60">
            {t("termsOfCondition.readAndAccept")}
          </p>
          <div className="flex gap-4 mt-2">
            <Button onClick={logout} variant="GhostWhite">
              {t("termsOfCondition.decline")}
            </Button>
            <Button onClick={() => updateHasAccepted()}>
              {t("termsOfCondition.accept")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
