import { createContext, ReactNode, useContext, useReducer } from "react";
import { PERIOD_RANGE } from "api/types";
import { Option } from "components/ButtonRadio/ButtonRadio";
import { getDatesFromPeriod, startOfYear, toLongISOString } from "utils/date";

export const options: Option[] = [
  // { label: "Från start", id: periodRange.FROM_START },
  { label: "Vald period", id: PERIOD_RANGE.GIVEN },
  { label: "Denna månad", id: PERIOD_RANGE.THIS_MONTH },
  { label: "Föregående månad", id: PERIOD_RANGE.LAST_MONTH },
  { label: "Detta kvartal", id: PERIOD_RANGE.THIS_QUARTER },
  { label: "Föregående kvartal", id: PERIOD_RANGE.LAST_QUARTER },
  { label: "Detta halvår", id: PERIOD_RANGE.THIS_HALF },
  { label: "Föregående halvår", id: PERIOD_RANGE.LAST_HALF },
  { label: "I år", id: PERIOD_RANGE.THIS_YEAR },
  { label: "Föregående år", id: PERIOD_RANGE.LAST_YEAR },
  { label: "Senaste 1 år", id: PERIOD_RANGE.LAST_1_YEARS },
  { label: "Senaste 3 år", id: PERIOD_RANGE.LAST_3_YEARS },
  { label: "Senaste 5 år", id: PERIOD_RANGE.LAST_5_YEARS },
  { label: "Senaste 7 dagarna", id: PERIOD_RANGE.LAST_7_DAYS },
];

type DateRange = {
  startDate?: string;
  endDate?: string;
  dataTimePeriodCode?: string;
  timePeriodCodes?: string[];
};

type DateRangeState = {
  startDate: Date;
  endDate: Date;
  period: PERIOD_RANGE;
  dateRange: DateRange;
  fetchPolicyString?: string;
};
type DateRangeAction = {
  payload: {
    value: Date | PERIOD_RANGE;
  };
  type: ACTION_TYPES;
};

export enum ACTION_TYPES {
  SET_START_DATE,
  SET_END_DATE,
  SET_PERIOD,
}

type ContextProps = {
  startDate: Date;
  endDate: Date;
  period: PERIOD_RANGE;
  dateRange: DateRange;
  fetchPolicyString?: string;
};

const DateRangeContext = createContext<ContextProps | undefined>(undefined);

export type DateRangeDispatchContextType = {
  dispatch: React.Dispatch<DateRangeAction>;
};
export const DateRangeDispatchContext = createContext<
  DateRangeDispatchContextType | undefined
>(undefined);

const dateRangeReducer = (
  state: DateRangeState,
  action: DateRangeAction
): DateRangeState => {
  const { payload, type } = action;
  switch (type) {
    case ACTION_TYPES.SET_START_DATE:
      return {
        ...state,
        startDate: payload.value as Date,
        period: PERIOD_RANGE.GIVEN,
        dateRange: {
          dataTimePeriodCode: PERIOD_RANGE.GIVEN,
          timePeriodCodes: [PERIOD_RANGE.GIVEN],
          startDate: toLongISOString(payload.value as Date),
          endDate: toLongISOString(state.endDate),
        },
        fetchPolicyString: `${toLongISOString(
          payload.value as Date
        )}.${toLongISOString(state.endDate)}`,
      };
    case ACTION_TYPES.SET_END_DATE:
      return {
        ...state,
        endDate: payload.value as Date,
        period: PERIOD_RANGE.GIVEN,
        dateRange: {
          dataTimePeriodCode: PERIOD_RANGE.GIVEN,
          timePeriodCodes: [PERIOD_RANGE.GIVEN],
          startDate: toLongISOString(state.startDate),
          endDate: toLongISOString(payload.value as Date),
        },
        fetchPolicyString: `${toLongISOString(
          state.startDate
        )}.${toLongISOString(payload.value as Date)} `,
      };
    case ACTION_TYPES.SET_PERIOD: {
      const period = payload.value as PERIOD_RANGE;
      const { startDate, endDate } = getDatesFromPeriod(period);
      return {
        ...state,
        startDate,
        endDate,
        period,
        dateRange: {
          dataTimePeriodCode: period,
          timePeriodCodes: [period],
        },
        fetchPolicyString: period as string,
      };
    }
    default:
      return state;
  }
};

export const DateRangeProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(dateRangeReducer, {
    startDate: startOfYear(new Date()),
    endDate: new Date(),
    period: PERIOD_RANGE.THIS_YEAR,
    dateRange: {
      dataTimePeriodCode: PERIOD_RANGE.THIS_YEAR,
      timePeriodCodes: [PERIOD_RANGE.THIS_YEAR],
    } as DateRange,
    fetchPolicyString: PERIOD_RANGE.THIS_YEAR,
  });

  return (
    <DateRangeContext.Provider value={{ ...state }}>
      <DateRangeDispatchContext.Provider value={{ dispatch }}>
        {children}
      </DateRangeDispatchContext.Provider>
    </DateRangeContext.Provider>
  );
};

export const useDateRangeState = () => {
  const state = useContext(DateRangeContext);
  if (!state)
    throw new Error(
      "useDateRangeState must be used within a DateRangeProvider"
    );

  return state;
};

export const useDateRangeDispatch = () => {
  const context = useContext(DateRangeDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useDateRangeDispatch must be used within a DateRangeProvider"
    );
  }
  return context;
};
