import { z } from "zod";

export const tradeOrderSignaturesSchema = z.object({
  signatories: z.array(
    z.object({
      name: z.string().min(1, "Name is required"),
      email: z.string().email("Invalid email address"),
    })
  ),
});
