import { lazy } from "react";
import { PortfolioGuard, TranslationText } from "components";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { NavTabRoutes } from "layouts/NavTabLayout/NavTab/NavTabRoutes";
import { NavTabPath } from "layouts/NavTabLayout/NavTab/types";
import { PortfolioNavigationHeaderLayout } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeaderLayout";
import { usePostNotification } from "providers/PostNotificationProvider";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

const Overview = lazy(() =>
  import("./overview").then((module) => ({ default: module.OverviewPage }))
);
const Holdings = lazy(() =>
  import("./holdings").then((module) => ({ default: module.HoldingsView }))
);
const Holding = lazy(() =>
  import("./holdings/[holdingId]").then((module) => ({
    default: module.HoldingPage,
  }))
);
const Transactions = lazy(() =>
  import("./transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const TransactionDetails = lazy(() =>
  import("./transactions/[transactionId]").then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);
const Orders = lazy(() =>
  import("./orders").then((module) => ({ default: module.OrdersPage }))
);
const OrderDetails = lazy(() =>
  import("./orders/[orderId]").then((module) => ({
    default: module.OrderDetailsPage,
  }))
);
const Documents = lazy(() =>
  import("./documents").then((module) => ({ default: module.DocumentsPage }))
);
const Contact = lazy(() =>
  import("./contact").then((module) => ({ default: module.ContactPage }))
);

const Posts = lazy(() =>
  import("../posts").then((module) => ({ default: module.PostsPage }))
);

const PostPage = lazy(() =>
  import("../posts/[postId]").then((module) => ({ default: module.PostPage }))
);

const Performance = lazy(() =>
  import("./performance").then((module) => ({
    default: module.PerformancePage,
  }))
);

const Bookkeeping = lazy(() =>
  import("./bookkeeping").then((module) => ({
    default: module.BookkeepingsPage,
  }))
);
const Limits = lazy(() =>
  import("./limits").then((module) => ({ default: module.LimitsPage }))
);
const Reports = lazy(() =>
  import("./reports").then((module) => ({ default: module.ReportsPage }))
);

const Securities = lazy(() =>
  import("./securities").then((module) => ({ default: module.SecuritiesPage }))
);

const SecurityDetails = lazy(() =>
  import("./securities/[securityId]").then((module) => ({
    default: module.SecurityDetailsPage,
  }))
);
const Issuers = lazy(() =>
  import("./issuers").then((module) => ({
    default: module.IssuersPage,
  }))
);
const IssuerDetails = lazy(() =>
  import("./issuers/[issuerId]").then((module) => ({
    default: module.IssuerDetailsPage,
  }))
);

const Sustainability = lazy(() =>
  import("../sustainability").then((module) => ({
    default: module.SustainabilityPage,
  }))
);

export const portfolioTabRoutes = (allowedRoutes: string[]): NavTabPath[] => {
  return [
    {
      path: "overview",
      icon: <span className="text-2xl mgc_home_3_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.overview" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("overview")}>
          <PortfolioGuard>
            <Overview />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "holdings",
      icon: <span className="text-2xl mgc_chart_pie_2_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.holdings" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("holdings")}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <PortfolioGuard>
              <Holdings />
            </PortfolioGuard>
          ),
        },
        {
          path: ":holdingId",
          element: <Holding />,
        },
      ],
    },
    {
      path: "transactions",
      icon: <span className="text-2xl mgc_exchange_euro_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.transactions" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("transactions")}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <Transactions />,
        },
        {
          path: ":transactionId",
          element: <TransactionDetails />,
        },
      ],
    },
    {
      path: "orders",
      icon: <span className="text-2xl mgc_transfer_horizontal_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.orders" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("orders")}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <Orders />,
        },
        {
          path: ":orderId",
          element: <OrderDetails />,
        },
      ],
    },
    {
      path: "performance",
      icon: <span className="text-2xl mgc_trending_up_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.performance" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("performance")}>
          <PortfolioGuard>
            <Performance />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "limits",
      icon: <span className="text-2xl mgc_balance_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.limits" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("limits")}>
          <PortfolioGuard>
            <Limits />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "reports",
      icon: <span className="text-2xl mgc_document_2_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.reports" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("reports")}>
          <PortfolioGuard>
            <Reports />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "bookkeeping",
      icon: <span className="text-2xl mgc_book_2_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.bookkeeping" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("bookkeeping")}>
          <PortfolioGuard>
            <Bookkeeping />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "securities",
      icon: <span className="text-2xl mgc_list_search_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.securities" />,

      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("securities")}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <PortfolioGuard>
              <Securities />
            </PortfolioGuard>
          ),
        },
        {
          path: ":securityId",
          element: <SecurityDetails />,
        },
        {
          path: ":securityId/:issuerId",
          element: <IssuerDetails />,
        },
      ],
    },
    {
      path: "issuers",
      icon: <span className="text-2xl mgc_building_1_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.issuers" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("issuers")}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <PortfolioGuard>
              <Issuers />
            </PortfolioGuard>
          ),
        },
        {
          path: ":issuerId",
          element: <IssuerDetails />,
        },
      ],
    },
    {
      path: "feed",
      icon: <span className="text-2xl mgc_news_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.feed" />,
      element: (
        <ProtectedRoute isAllowed={true}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <Posts />,
        },
        {
          path: ":postId",
          element: <PostPage />,
        },
      ],
    },
    {
      path: "sustainability",
      icon: <span className="text-2xl mgc_leaf_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.sustainability" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("sustainability")}>
          <PortfolioGuard>
            <Sustainability />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "documents",
      icon: <span className="text-2xl mgc_pdf_line"></span>,
      tabLabel: <TranslationText translationKey="navTab.tabs.documents" />,
      element: (
        <ProtectedRoute isAllowed={allowedRoutes.includes("documents")}>
          <PortfolioGuard>
            <Documents />
          </PortfolioGuard>
        </ProtectedRoute>
      ),
    },
  ];
};

const contact = {
  path: "contact",
  icon: <span className="text-2xl mgc_phone_line"></span>,
  tabLabel: <TranslationText translationKey="navTab.tabs.contact" />,
  element: <Contact />,
};

export const portfolioRoutes = (routes: NavTabPath[]) => {
  return [
    {
      path: "",
      element: (
        <Navigate
          to={routes.find((route) => !route.disabled)?.path ?? "/contact"}
          replace
        />
      ),
    },
    {
      path: "",
      element: <PortfolioNavigationHeaderLayout />,
      children: [
        {
          path: "*",
          element: <NavTabRoutes routes={routes} />,
        },
      ],
    },
  ];
};

interface PortfolioRoutesProps {
  allowedRoutes: string[];
}

export const PortfolioRoutes = ({ allowedRoutes }: PortfolioRoutesProps) => {
  const { newPost } = usePostNotification();
  const accessedRoutes = portfolioTabRoutes(allowedRoutes).map((route) => ({
    ...route,
    disabled: !allowedRoutes.includes(route.path),
    notification: route.path === "feed" && newPost,
  }));

  return useRoutes(portfolioRoutes([...accessedRoutes, contact]));
};
