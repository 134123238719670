import { useGetContactPages } from "api/initial/useGetContactPages";
import { Card, ErrorMessage } from "components";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

import { UserWithLinkedContactRoutes } from "./routes";

export const Start = () => {
  const { t } = useModifiedTranslation();
  const { loading, error, data } = useGetContactPages();

  if (data) {
    return <UserWithLinkedContactRoutes data={data} />;
  }

  if (!loading || (error && !data)) {
    return (
      <Card bg="transparent">
        <ErrorMessage header={t("messages.queryError")}>
          {t("messages.problemResolveInstructions")}
        </ErrorMessage>
      </Card>
    );
  }
  return (
    <div className="h-screen">
      <LoadingIndicator center />
    </div>
  );
};
