import { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";
import { Option } from "./EmailIssuersModalContent";

interface RadioProps<T> {
  options: Option<T>[];
  name: string;
  label: string;
  onSelect: (value: Option<T>) => void;
  selected: Option<T>;
}

export function Radio<T extends string>({
  options,
  label,
  name,
  onSelect,
  selected,
}: RadioProps<T>) {
  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const item = options.find((option) => option.id === e.target.value);
    if (item) {
      onSelect(item);
    }
  };
  return (
    <div>
      {/* <label className="text-sm font-normal">{label}</label> */}
      <div className="grid grid-cols-2 gap-2">
        {options.map(({ id, label }) => (
          <div
            key={id}
            className={twMerge(
              "py-4 px-2 rounded border gap-2 flex flex-col justify-center items-center relative transition-colors",
              id === selected?.id && "bg-secondary-5 border-secondary-100"
            )}
          >
            <input
              className="transition-all"
              key={id}
              id={id}
              value={id}
              onChange={handleOptionChange}
              type="radio"
              name={name}
              checked={id === selected?.id}
            />
            <label
              className="absolute w-full h-full text-center opacity-0"
              htmlFor={id}
            >
              {label}
            </label>
            <span className="order-first text-sm">{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
