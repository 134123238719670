import { ForwardedRef, forwardRef, HTMLProps } from "react";
import classNames from "classnames";

interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  label: string;
  error?: string;
}

const TextareaPlain = (
  { label, className, error, ...textareaAttributes }: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) => (
  <label
    className={classNames("block text-sm font-normal", {
      "text-red-700": !!error,
    })}
  >
    {label}
    <textarea
      ref={ref}
      className={classNames(
        "block p-2.5 w-full text-body-small text-primary-100 rounded border border-primary-10 focus:border-secondary-40 ",
        className,
        {
          "text-red-900 placeholder-red-700 bg-red-50 focus:border-red-500 border-red-500":
            !!error,
        }
      )}
      {...textareaAttributes}
    />
    {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
  </label>
);

export const Textarea = forwardRef(TextareaPlain);
