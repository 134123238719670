import { HamburgerMenu, Logo } from "components";
// import {
//   TOTAL_INVESTMENTS_OPTION_ID,
//   useGetPortfolioOptions,
// } from "hooks/useGetPortfolioOptions";

import { useScrollDirection, DIRECTION } from "hooks/useScrollPosition";
import { ACTIONS, useNavTabProvider } from "providers/NavTabProvider";
// import { useParams } from "react-router-dom";
// import { useNavigateToPortfolioTab } from "./useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "./useRedirectIfOnlyOnePortfolio";

export interface PortfolioOption {
  id: number;
  urlPrefix: string;
  label: string;
}

export const PortfolioNavigationHeaderMobile = () => {
  // const { parentPortfolioOptions, portfolioOptions } = useGetPortfolioOptions();
  // const { portfolioId } = useParams();
  // const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();
  // const onPortfolioChange = (selectedOption: PortfolioOption) => {
  //   navigateToPortfolioTab(selectedOption.urlPrefix);
  // };

  const { isOpen, dispatch } = useNavTabProvider();

  const handleToggle = () => {
    dispatch({
      type: ACTIONS.TOGGLE,
    });
  };

  // const currentPortfolio = portfolioId
  //   ? parseInt(portfolioId, 10)
  //   : TOTAL_INVESTMENTS_OPTION_ID;

  //TODO: Hämta alla profiolios i en provider så att dem finns överst i hierarkin
  // if (
  //   currentPortfolio !== TOTAL_INVESTMENTS_OPTION_ID /**&&
  //   !portfolioOptions.some((option) => option.id === currentPortfolio)**/
  // ) {
  //   console.log("redirecting to root");
  //   return <Navigate to="/" replace />;
  // }
  //   const { toggleNav } = useNavTab();

  const { scrollDirection, isAtTop } = useScrollDirection();

  const showMenu = isAtTop || scrollDirection === DIRECTION.UP;

  const styles = {
    active: "top-0 transition-all duration-500 ease-in-out",
    hidden: "-top-24 transition-all duration-500 ease-in-out",
  };

  return (
    <div
      id="sentinal"
      className={`flex fixed w-full z-30 justify-between items-center p-6 bg-white-100 border-b border-primary-20 h-[96px] ${
        showMenu ? styles.active : styles.hidden
      }`}
    >
      <HamburgerMenu toggled={isOpen} handleToggle={handleToggle} />
      <div className="flex-none w-[160px]">
        <Logo large />
      </div>
    </div>
  );
};
