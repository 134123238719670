import {
  HTMLAttributes,
  Fragment,
  MouseEvent,
  useState,
  forwardRef,
} from "react";
import { Transition } from "@headlessui/react";
import Portal from "hoc/Portal";

interface TooltipProps extends HTMLAttributes<HTMLElement> {
  text: string;
  children: React.ReactNode;
}

export const Tooltip = ({ text, children, className }: TooltipProps) => {
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const mouseEnter = (e: MouseEvent<HTMLElement>) => {
    const { top, width, height } = e.currentTarget.getBoundingClientRect();
    const tooltipHeight = 28;
    setCoords({
      left: e.clientX - width / 2,
      top: top - height - tooltipHeight / 2,
    });
    setShowTooltip(true);
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={className}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {children}

      <Portal
        create={false}
        className="fixed top-0 left-0 z-50"
        id="portal-root"
        tag="div"
      >
        <Transition
          show={showTooltip}
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Content top={coords.top} left={coords.left} text={text} />
        </Transition>
      </Portal>
    </div>
  );
};

interface ContentProps {
  top: number;
  left: number;
  text: string;
}

const Content = forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const { top, left, text } = props;
  return (
    <div
      ref={ref}
      className="flex fixed items-center py-1 px-3 text-white-100 rounded backdrop-blur-3xl bg-primary-100/40"
      style={{ left: left, top: top }}
    >
      <span className="text-body-small">{text}</span>
    </div>
  );
});
Content.displayName = "Content";
