import { Logo, PortfolioSelect, UserMenu } from "components";
import ContactIssuers from "components/ContactIssuers/ContactIssuers";
import {
  TOTAL_INVESTMENTS_OPTION_ID,
  useGetPortfolioOptions,
} from "hooks/useGetPortfolioOptions";
import { Navigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useNavigateToPortfolioTab } from "./useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "./useRedirectIfOnlyOnePortfolio";

export interface PortfolioOption {
  id: number;
  urlPrefix: string;
  label: string;
}

export const PortfolioNavigationHeader = () => {
  const portfolioOptions = useGetPortfolioOptions();
  const { contactDbId, portfolioId: portfolioIdUrl } = useParams();
  const portfolioId = portfolioIdUrl
    ? parseInt(portfolioIdUrl, 10)
    : TOTAL_INVESTMENTS_OPTION_ID;
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();
  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
  };

  // redirect to root when portfolioId does not match available portfolios
  if (
    portfolioId !== TOTAL_INVESTMENTS_OPTION_ID &&
    !portfolioOptions.some(
      (option) =>
        option.id === portfolioId ||
        //Extend this to a recursive operation to check subs of subs
        option?.subOptions?.some((subOption) => subOption.id === portfolioId)
    )
  ) {
    //handle impersonation mode
    if (contactDbId) return <Navigate to={`/impersonate/${contactDbId}/`} />;
    return <Navigate to="/" replace />;
  }
  return (
    <div
      id="sentinal"
      className={twMerge(
        "flex sticky top-0 z-20 py-6 bg-white-100 border-b border-primary-20 max-h-[96px]"
      )}
    >
      <div className="flex-none px-4 w-[200px]">
        <Logo large />
      </div>
      <div className="flex gap-2 justify-between items-center mx-14 w-full">
        <div className="flex-auto flex-shrink justify-start w-1/2 sm:min-w-[350px]">
          {portfolioOptions.length > 0 ? (
            <div className="max-w-[350px]">
              <PortfolioSelect
                portfolioOptions={portfolioOptions}
                portfolioId={portfolioId}
                onChange={onPortfolioChange}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="flex gap-6">
          <ContactIssuers />
          <UserMenu />
        </div>
      </div>
    </div>
  );
};
